import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { TcmsContext } from "../../../contexts/TcmsContext";
import DialogLogout from "./DialogLogout";
import Tcms from "../../../tcms/Tcms";
import { useHistory } from "react-router-dom";
import { FilterContext } from "../../../contexts/FilterContext";
import VirtualDialogInfo from "../../virtual/dialogs/VirtualDialogInfo";
import VirtualDialogOrder from "../../virtual/dialogs/VirtualDialogOrder";
import VirtualDialogPortal from "../../virtual/dialogs/VirtualDialogPortal";

const MenuItems = () => {
  const context = useContext(TcmsContext)
  const filterContext = useContext(FilterContext)
  const history = useHistory()
  const refDialogLogout = useRef()
  const refVirtualDialogInfo = useRef()
  const refVirtualDialogOrder = useRef()
  const refVirtualDialogPortal = useRef()
  const [activeItem, setActiveItem] = useState()

  // actions in tabChanged()  below
  const items = useMemo(() => {
    const shopLabel = (context.virtualMarketSlug !== '' && context.loadedComponent === 'Webshop')
      ? '_shop_virtueel_'
      : '_shop_webshop_'
  
    if (context.customHeader && context.loadedComponent !== 'PageVirtualMarket') {
  
      let customShopLabel = (window.location.pathname !== context.webshopSlug + '/plein' && context.loadedComponent === 'Webshop')
        ? '_shop_plein_'
        : '_shop_webshop'
      if (!context.welcomeScreenIntro) {
        customShopLabel = shopLabel
      }
      const menuItems = [
        { key: 'shop', 
          url: `${Tcms('geschenken')}`, 
          default: 'geschenken',
          label: customShopLabel, 
          to: `/${context.webshopSlug}/webshop/${Tcms('geschenken')}`, 
          icon: "/media/icons/webshop.png" 
        },
        { key: 'portal', 
          url: `${Tcms('portal')}`,
          default: 'portal',
          label: 'Voorwoord', 
          to: `/${context.webshopSlug}/${Tcms('portal')}`, 
          icon: "/media/icons/chat.png" 
        },
        { key: 'info', 
          url: `${Tcms('hoe-werkt-het')}`, 
          default: 'hoe-werkt-het',
          label: 'Extra Info', 
          to: `/${context.webshopSlug}/${Tcms('hoe-werkt-het')}`, 
          icon: "/media/icons/info.png" 
        },
        { key: 'logout', 
          url: 'logout',
          default: 'logout',
          label: 'Uitloggen', 
          to: 'logout', 
          icon: "/media/icons/uitloggen.png" 
        },
      ]
  
      if ([1103, 945].includes(context.meta.wso_id)) {
        const gamesMenuItem = {
          key: 'games',
          url: '/games',
          default: 'games',
          label: 'Naar spel',
          to: '/games'
        }
        const infoIndex = menuItems.findIndex(item => item.key === 'shop')
        menuItems.splice(infoIndex, 0, gamesMenuItem)
      }
      return menuItems
  
    } else {
      const menuItems = context.meta.homepage ? [
        { key: 'home', 
          url: `${Tcms('home_url')}`,
          default: 'home_url', 
          label: 'Home', 
          to: `/${context.webshopSlug}/${Tcms('home_url')}`, 
          icon: "/media/icons/chat.png" 
        },
        { key: 'portal',
          url: `${Tcms('portal')}`,
          default: 'portal',
          label: 'Voorwoord', 
          to: `/${context.webshopSlug}/${Tcms('portal')}`, 
          icon: "/media/icons/chat.png" 
        },
        { key: 'shop', 
          url: `${Tcms('geschenken')}`, 
          default: 'geschenken',
          label: shopLabel, 
          to: `/${context.webshopSlug}/webshop/${Tcms('geschenken')}`, 
          icon: "/media/icons/webshop.png" 
        },
        { key: 'info', 
          url: `${Tcms('hoe-werkt-het')}`, 
          default: 'hoe-werkt-het',
          label: 'Extra Info', 
          to: `/${context.webshopSlug}/${Tcms('hoe-werkt-het')}`, 
          icon: "/media/icons/info.png" 
        },
        { key: 'order', 
          url: `${Tcms('bestellingen')}`, 
          default: 'bestellingen',
          label: 'Mijn Bestelling', 
          to: `/${context.webshopSlug}/${Tcms('bestellingen')}`, 
          icon: "/media/icons/mijn_bestelling.png" 
        },
        { key: 'logout', 
          url: 'logout',
          default: 'logout',
          label: 'Uitloggen', 
          to: 'logout', 
          icon: "/media/icons/uitloggen.png" 
        },
      ] : [
        { key: 'shop', 
          url: `${Tcms('geschenken')}`, 
          default: 'geschenken',
          label: shopLabel, 
          to: `/${context.webshopSlug}/webshop/${Tcms('geschenken')}`, 
          icon: "/media/icons/webshop.png" 
        },
        { key: 'portal',
          url: `${Tcms('portal')}`, 
          default: 'portal',
          label: 'Voorwoord', 
          to: `/${context.webshopSlug}/${Tcms('portal')}`, 
          icon: "/media/icons/chat.png" 
        },
        { key: 'info', 
          url: `${Tcms('hoe-werkt-het')}`, 
          default: 'hoe-werkt-het',
          label: 'Extra Info', 
          to: `/${context.webshopSlug}/${Tcms('hoe-werkt-het')}`, 
          icon: "/media/icons/info.png" 
        },
        { key: 'order', 
          url: `${Tcms('bestellingen')}`, 
          default: 'bestellingen',
          label: 'Mijn Bestelling', 
          to: `/${context.webshopSlug}/${Tcms('bestellingen')}`, 
          icon: "/media/icons/mijn_bestelling.png" 
        },
        { key: 'logout', 
          url: 'logout',
          default: 'logout',
          label: 'Uitloggen', 
          to: 'logout', 
          icon: "/media/icons/uitloggen.png" 
        },
      ]
  
      if ([1103, 945].includes(context.meta.wso_id)) {
        const gamesMenuItem = {
          key: 'games',
          url: '/games',
          default: 'games',
          label: 'Naar spel',
          to: '/games'
        }
        const infoIndex = menuItems.findIndex(item => item.key === 'shop')
        menuItems.splice(infoIndex, 0, gamesMenuItem)
      }
  
      return menuItems
    }
  
  }, [context.webshopSlug, context.virtualMarketSlug, context.loadedComponent, context.meta.wso_id])
  

  // This is here for a hard page reload
  useEffect(() => {
    setActiveItem(items.findIndex(el => el.to === history.location.pathname))
  }, [items, history])

  const getInactiveClass = (value) => {
    if (value === "inactive") {
      return value;
    } else {
      return '';
    }
  }

  // Nav clicked
  const tabChanged = (index) => {
    setActiveItem(index)
    switch (items[index].key) {
      case 'shop':
        filterContext.clear()
        const hc = context.products.categories.find(el => el.id === filterContext.activeHoofdCatId)

        if (context.customHeader && context.welcomeScreenIntro) {
          if (window.location.pathname !== context.webshopSlug + '/plein' && context.loadedComponent === 'Webshop') {
            // Have VM and page is Webshop .. navigate to VM
            history.push(`/${context.webshopSlug}/plein`)
          } else {
            // No VM or VM but page !== 'Webshop' navigate to webshop
            history.push(`/${context.webshopSlug}/webshop`)
          }
        } else {
          if (context.virtualMarketSlug !== '' && context.loadedComponent === 'Webshop') {
            // Have VM and page is Webshop .. navigate to VM
            history.push(`/${context.webshopSlug}/virtueel`)
          } else {
            // No VM or VM but page !== 'Webshop' navigate to webshop
            history.push(`/${context.webshopSlug}/webshop`)
          }
        }


        break
      case 'portal':
        if (context.pageIsVM) {
          refVirtualDialogPortal.current.open()
        } else {
          history.push(items[index].to)
        }
        break
      case 'home':
        if (context.pageIsVM) {
          refVirtualDialogPortal.current.open()
        } else {
          history.push(items[index].to)
        }
        break
      case 'info':
        if (context.pageIsVM) {
          refVirtualDialogInfo.current.open()
        } else {
          history.push(items[index].to)
        }
        break
      case 'order':
        if (context.pageIsVM) {
          refVirtualDialogOrder.current.open()
        } else {
          history.push(items[index].to)
        }
        break
      case 'games':
        history.push(items[index].to)
          break
      case 'logout':
        refDialogLogout.current.open()
        break
      case null:
        break
      default:

    }
  }
  // Nav items (HTML)
  const navHTML = () => {
    const balance = context.getBalance()
    return <ul>
      {context.editMode && 
      <div className="tcms-edit-wrapper tcms-overlay overlay-left overlay-large">
        <div className="item">
          <div className="label">
            Logo class: 
          </div>
          <div className="value">
            <Tcms>logo_class</Tcms>
          </div>
        </div>
        <div className="item">
          <div className="label">
            Langswitch toggle: 
          </div>
          <div className="value">
            <Tcms>langswitch_toggle</Tcms>
          </div>
        </div>
        {items.filter(item => item.label != null).map((el, key) => {
          return el.url && (
            <div className="item">
              <div className="label"> 
                Route for {el.label}:                 
              </div>
              <div className="value">
                /<Tcms>{el.default}</Tcms>
              </div>
            </div>
          )
        }
        )}
      </div>
      }
      {items.filter(item => item.label != null).map((el, key) =>
        <>
          <li key={key} data-key={key} data-to={Tcms(el.to)} className={key === activeItem ? 'active' + ' '+getInactiveClass(Tcms(el.default)) : ''+ ' '+getInactiveClass(Tcms(el.default))}>
            <img className="menu-icon" alt="" src={el.icon} />
            <button onClick={(e) => {
              e.preventDefault()
              tabChanged(key)
            }}>
              <Tcms>{el.label}</Tcms>
            </button>
          </li>
          {el.key === 'info' && context.customHeader &&
            <>
              <li key={997} className="visible-sm">
                <button>
                  <a style={{ color: '#000', textDecoration: 'none' }} href="/" onClick={(e) => {
                    e.preventDefault()
                    history.push(`/${context.webshopSlug}/favorites`)
                  }}><Tcms>Favorites</Tcms></a>
                </button>
              </li>
              <li key={998} className="visible-sm">
                <button>
                  <a style={{ color: '#000', textDecoration: 'none' }} href="/" onClick={(e) => {
                    e.preventDefault()
                    history.push(`/${context.webshopSlug}/bestellingen`)
                  }}><Tcms>Mijn Bestelling</Tcms></a>
                </button>
              </li>
            </>
          }
        </>
      )}
      <li key={996} className="visible-xs">
        <button>
          <a style={{ color: '#fff', textDecoration: 'none' }} href="https://morethangifts.zendesk.com/hc/nl" target="_blank">Helpdesk</a>
        </button>
      </li>

    </ul>
  }

  return <>
    <DialogLogout ref={refDialogLogout} />
    <VirtualDialogInfo ref={refVirtualDialogInfo} />
    <VirtualDialogOrder ref={refVirtualDialogOrder} />
    <VirtualDialogPortal ref={refVirtualDialogPortal} />
    {navHTML()}
  </>


}
export default MenuItems
