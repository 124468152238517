import React, { forwardRef, useImperativeHandle, useState} from 'react';
import { Dialog } from "primereact/dialog";
import Tcms from '../../tcms/Tcms';

const DialogPickupPoint = forwardRef((props, ref) => {

  const [visible, setVisible] = useState();

  useImperativeHandle(ref, () => ({
    open() {
      if (props.toggle === "inactive") {
        setVisible(false);
      } else {
        setVisible(true);
      }
    }
  }));

  return (
    <Dialog
    header={<Tcms>afhaalpunt_titel</Tcms>}
      visible={visible}
      className={props.className}
      position="top"
      modal={true}
      onHide={() => setVisible(false)}
      >
      <div className="dialog-content">
        <div className='p-grid'>
          <div className='p-col-12'>
          <Tcms>afhaalpunt_tooltip</Tcms>
            <div className="close-button-container">
              <button
                onClick={() => setVisible(false)}
                className="p-button p-component"
              >
                <span class="p-button-label p-c">Ga verder</span>
                
              </button>
            </div>
          </div>
        </div>
      </div>
     
    </Dialog>
  );
});

export default DialogPickupPoint;
