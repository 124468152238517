import TcmsImg from "../../../tcms/Tags/TcmsImg";
import Tcms from "../../../tcms/Tcms";
import TcmsSpan from "../../../tcms/Tags/TcmsSpan";
import { TcmsContext } from "../../../contexts/TcmsContext";
import { useContext } from "react";


const videoLink = "https://video214.com/play/idzb1fRG25Aql1KbZtYDww/s/dark";

const Helpdesk = () => {
  const context = useContext(TcmsContext)
  return <div className="helpdesk">
    <div className="image">
      <TcmsImg src={`/media/icons/helpdesk.svg`}/>
    </div>
    <div className="helpdesk-info">
      <h3><Tcms>Wij helpen je graag</Tcms></h3>
      <ul>
        <li>
          <a href="https://morethangifts.zendesk.com/hc/nl/requests/new" target="_blank" rel="noreferrer">
            <span className="icon"><img alt="" src="/media/icons/email.svg"/></span>
            <TcmsSpan className="info">Contact formulier</TcmsSpan>
          </a>
        </li>
        <li>
          <a href="https://wa.me/0031620491322">
            <span className="icon"><img alt="" src="/media/icons/whatsapp.svg"/></span>
            <span className="info">
              <Tcms>Stuur een WhatsApp.</Tcms>
              <br/>
              <Tcms>06 - 20 49 13 22</Tcms>
            </span>
          </a>
        </li>
        <li>
          <a href="tel:0880273600">
            <span className="icon"><img alt="" src="/media/icons/phone.svg"/></span>
            <span className="info">
              <Tcms>Bel met ons.</Tcms>
              <br/>
              <Tcms>088 - 027 3600</Tcms>
            </span>
          </a>
        </li>
        {context.webshopId !== 693 && context.webshopId !== 942 &&
          <li>
            <a href={'/'+context.webshopSlug+'/instructie-video'} rel="noreferrer">
              <span className="icon"><img alt="" src="/media/icons/video.svg"/></span>
              <TcmsSpan className="info">Bekijk instructiefilm</TcmsSpan>
            </a>
            {context.editMode &&
              <Tcms>{videoLink}</Tcms>
            }
          </li>
        }
        <li>
          <a href={'/'+context.webshopSlug + '/' + Tcms('hoe-werkt-het')} rel="noreferrer">
            <span className="icon"><img alt="" src="/media/icons/question.svg"/></span>
            <TcmsSpan className="info">Veelgestelde vragen</TcmsSpan>
          </a>
        </li>
      </ul>
    </div>
  </div>
}
export default Helpdesk
