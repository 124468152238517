import React, { useState, useEffect, useContext } from "react";
import VideoPlaceholder from "../video-placeholder/VideoPlaceholder.js";
import GameIntro from '../game-intro/GameIntro.js';
import GameResult from '../game-result/GameResult.js';
import { useHistory } from 'react-router-dom';
import "./WordSearch.scss";
import WordSearchGame from "./WordSearchGame.js";
import { storeGameData, getGameScoresByUser, updateGameData } from "../services/gameProgressService";
import { TcmsContext } from '../../../../contexts/TcmsContext.js';
import jwtDecode from 'jwt-decode';
import { toast } from 'react-hot-toast';
import gamesTransitionVideo from '../assets/games_transition6.mp4';
import pingSound from '../../../../sounds/ping.mp3';
import axios from "axios";

const WordSearch = ({ startGame, setIsMusicMuted, setIsIntro, fetchScore }) => {
  const { setJwtForGames } = useContext(TcmsContext);

  const gameIntroText = {
    title: 'Woordzoeker 🧩',
    description: `Na een lange en mooie tocht is daar bijna het einde in zicht, je bent bij de laatste tussenstop. Bij deze tussenstop staat de multipillow centraal, dit geschenk kan je kiezen in de webshop na afloop van de game. Bij deze tussenstop moet je een woordzoeker oplossen. De woorden die je zoekt hebben te maken met het thema: inclusiviteit. 
    Veel succes met zoeken en met het laatste spel!`,
    // instruction: 'Vraag iemand om je te helpen! Het is niet alleen handig, maar ook leuk! Scoor meer dan het bepaalde aantal punten om het item "sleutel" voor in je rugzak te verdienen 🗝️. Deze drempelwaarde wordt bepaald door de spelbegeleiders tijdens het spelen met het testpanel.',
    showMedals: false
  };

  const [correctLetters, setCorrectLetters] = useState(0);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(300);
  const [timeLeft, setTimeLeft] = useState(100);
  const [gameStarted, setGameStarted] = useState(false);
  const [gameFinished, setGameFinished] = useState(false);
  const [revealedLetters, setRevealedLetters] = useState(0);
  const [shouldShowEndScreen, setShouldShowEndScreen] = useState(false);
  const [foundWords, setFoundWords] = useState([]);
  const history = useHistory();
  const gameId = 6;
  const [userId, setUserId] = useState('');
  const [shopId, setShopId] = useState('');
  const [existingGameId, setExistingGameId] = useState(null);
  const wordSearchList = [
    "DANK",
    "STEUN",
    "BEGRIP",
    "COMPLIMENT",
    "ERKENNING",
    "TROTS",
    "RESPECT",
    "LOYAAL",
    "EERLIJKHEID",
    "BLIJ"
  ];

  useEffect(() => {
    const storedJwtToken = sessionStorage.getItem('jwt_token');
    if (storedJwtToken) {
      axios.defaults.headers.common['MtgJwt'] = storedJwtToken;

      const tokenData = jwtDecode(storedJwtToken);
      setUserId(tokenData?.usr?.uid);
      setShopId(tokenData?.usr?.wid);
      setJwtForGames(storedJwtToken);
    }
  }, [setJwtForGames]);

  const handleWordFound = (word) => {
    if (!foundWords.includes(word)) {
      setFoundWords((prevWords) => [...prevWords, word]);
    }
  };

  // Fetch existing game progress to check for existing entries
  useEffect(() => {
    const fetchGameProgress = async () => {
      try {
        const progress = await getGameScoresByUser(userId);
        const gameEntry = progress.result.find(
          (entry) => entry.game_id === gameId && entry.webshoporder_id === shopId
        );
        if (gameEntry) {
          setExistingGameId(gameEntry.id);
        }
      } catch (error) {
        console.error('Error fetching game progress:', error.message);
      }
    };
    if (userId) {
      fetchGameProgress();
    }
  }, [userId, shopId]);

  // Handle saving progress if the user tries to leave the page
  const handleBeforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = '';
    toast('Je kunt het spel niet opnieuw spelen als je de pagina verlaat!', {
      position: 'bottom-center',
      duration: 5000,
      style: {
        background: '#facc15',
        color: '#000',
      },
    });

    const saveProgress = async () => {
      try {
        if (existingGameId) {
          await updateGameData(existingGameId, correctLetters);
        } else {
          const response = await storeGameData(userId, gameId, shopId, correctLetters);
          setExistingGameId(response.result.id);
        }
      } catch (error) {
        console.error('Error saving progress on exit:', error.message);
      }
    };
    saveProgress();
  };

  useEffect(() => {
    if (gameStarted && !gameFinished) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [gameStarted, gameFinished, existingGameId, userId, gameId, shopId, correctLetters]);


  const beginGame = () => {
    setGameStarted(true);
    setTimeLeft(300);
    setProgress(100);
    setIsIntro(false);
  };

  useEffect(() => {
    if (gameStarted && timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft(prevTime => prevTime - 1);
        setProgress((prevProgress) => (prevProgress - 100 / 300));
      }, 1000);

      return () => clearInterval(timer);
    } else if (timeLeft <= 0) {
      setGameFinished(true);
      setTimeout(() => {
        setShouldShowEndScreen(true);
      }, 1000);
    }
  }, [timeLeft, gameStarted]);

  const handleFinishGame = () => {
    // setLoading(true);
    // setTimeout(() => {
    //   setLoading(false);
    //   history.push('/games?game=7');
    // }, 3000);
  };

  const goToFinalResult = () => {
    setLoading(true);
    setTimeout(() => {
      history.push('/final-result');
    }, 7000);
  };

  const handleGameEvent = () => {
    if (revealedLetters < 10) {
      new Audio(pingSound).play();
      setRevealedLetters(prev => prev + 1);
      setCorrectLetters(prev => prev + 1);

      if (revealedLetters + 1 === 10) {
        setGameFinished(true);
        setTimeout(() => {
          setShouldShowEndScreen(true);
        }, 3000);
      }
    }
  };

  useEffect(() => {
    if (gameFinished) {
      const saveProgress = async () => {
        try {
          if (existingGameId) {
            // Update the existing entry
            await updateGameData(existingGameId, correctLetters);
          } else {
            // Create a new entry
            const response = await storeGameData(userId, gameId, shopId, correctLetters);
            setExistingGameId(response.result.id);
          }
          if (fetchScore) {
            fetchScore(true);
          }
        } catch (error) {
          console.error('Error saving game progress:', error.message);
        }
      };
      saveProgress();
    }
  }, [gameFinished]);

  if (!gameStarted && startGame) {
    return (
      <>
        <GameIntro {...gameIntroText} onStart={beginGame} setIsMusicMuted={setIsMusicMuted} />
      </>
    );
  }

  if (loading) {
    return (
      <div className="game-loader">
        <video
          src={gamesTransitionVideo}
          autoPlay
          muted
          onEnded={() => history.push('/final-result')}
          className="transition-video"
        />
      </div>
    );
  }

  if (shouldShowEndScreen) {
    return (
      <GameResult
        score={correctLetters}
        totalLetters={10}
        isWordSearch={true}
        onNextGame={handleFinishGame}
        goToFinalResult={goToFinalResult}
      />

    );
  }

  return (
    <div className={`game-screen ${!startGame ? 'game-screen-video' : ''}`}>
      <div className='highlight-current-game'> 6 </div>
      {startGame && (
        <div className="top-right-wooden-sign-container">
          <p>Vind zoveel mogelijk woorden in de woordzoeker</p>
        </div>
      )}
      {!startGame ? (
        <VideoPlaceholder setIsMusicMuted={setIsMusicMuted} />
      ) : (
        <div className="kruiswoordpuzzel-content">
          <div className="kruiswoordpuzzel-description">
            <h2>Woordzoeker</h2>
            <p>
              Vind alle woorden over waardering in de woordzoeker. De letters kunnen horizontaal, verticaal en diagonaal staan.
            </p>
            <p>
              <b> Tip: </b> <br />
              Vraag je collega, partner, vriend/vriendin, kennis of familie om te helpen.
            </p>
            <div className="progress-bar">
              <div className="progress" style={{ width: `${progress}%` }}></div>
            </div>
            <p className="resterende">Resterende tijd: {timeLeft} seconden</p>
            <div className="word-search-list">
              <h3>Zoek deze woorden:</h3>
              <ul>
                {wordSearchList.map((word, index) => (
                  <li
                    key={index}
                    className={foundWords.includes(word) ? "found" : ""}
                  >
                    {word}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="kruiswoordpuzzel-game">
            <WordSearchGame onGameEvent={handleGameEvent} onWordFound={handleWordFound} />
          </div>
        </div>
      )
      }
    </div>
  );
};


export default WordSearch;
