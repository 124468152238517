import {useContext} from 'react'
import {Helmet} from 'react-helmet'
import {TcmsContext} from "../contexts/TcmsContext";

export default function HtmlHeadService() {
  const context = useContext(TcmsContext)
  if(!context.meta) return null

  return <Helmet>
    <title>{context.meta.wso_name}</title>
    {/* <script async src="https://widget.thuiswinkel.org/script.js?id=NjQ5NC0x"></script> */}
    <link href="https://fonts.googleapis.com/css2?family=Caveat:wght@400..700&display=swap" rel="stylesheet"></link>
    <link href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap" rel="stylesheet"></link>
    {/* <script id="ze-snippet" src="https://static.zdassets.com/ekr/snippet.js?key=7600586b-2c7d-493f-b680-9ac660b35166"> </script> */}
  </Helmet>

}
