import React, { forwardRef, useImperativeHandle, useState, useContext } from 'react';
import { Dialog } from "primereact/dialog";
import TcmsWYSIWYG from '../../tcms/Tags/TcmsWYSIWYG';
import TcmsImg from '../../tcms/Tags/TcmsImg';
import Tcms from '../../tcms/Tcms';
import { TcmsContext } from '../../contexts/TcmsContext';

const DialogHome = forwardRef((props, ref) => {

  const [visible, setVisible] = useState();
  const context = useContext(TcmsContext);

  const checkElementVisibility = (value) => {
    if (value.includes("home_dialog_image")) {
      return false;
    } else {
      return true;
    }
  }

  useImperativeHandle(ref, () => ({
    open() {
      if (props.toggle === "inactive") {
        setVisible(false);
      } else {
        setVisible(true);
      }
    }
  }));

  return (
    <Dialog
      visible={visible}
      className={props.className}
      position="top"
      modal={true}
      onHide={() => setVisible(false)}
      >
      {context.editMode &&
        <div className='tcms-edit-wrapper'>
          <div className='item'>
            <div className="label">Dialog image</div>
            <div className="value"><TcmsImg className="tcms-image-edit" src="home_dialog_image"/></div>
            
          </div>
        </div>
      }
      <div className="dialog-content">
        
        <div className='p-grid'>
          {checkElementVisibility(Tcms('home_dialog_image')) 
          ?
          <>
            <div className='p-sm-12 p-md-4 p-col p-col-12 col-left'>
              <TcmsImg className="dialog-home-image" src="home_dialog_image"/>
            </div>
            <div className='p-sm-12 p-md-8 p-col p-col-12 col-right'>
              <TcmsWYSIWYG>home_dialogue_content</TcmsWYSIWYG>
              <div className="close-button-container">
                <button
                  onClick={() => setVisible(false)}
                  className="p-component"
                >
                  Ga verder
                </button>
              </div>
            </div>
          </>
          :
          <>
            <div className='p-col-12'>
              <TcmsWYSIWYG>home_dialogue_content</TcmsWYSIWYG>
              <div className="close-button-container">
                <button
                  onClick={() => setVisible(false)}
                  className="p-component"
                >
                  Ga verder
                </button>
              </div>
            </div>
          </>
          }
          
        </div>
      </div>
     
    </Dialog>
  );
});

export default DialogHome;
