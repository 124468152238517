import React from 'react';
import './PrizeModal.scss';

function PrizeModal({
  show,
  onClose,
  children,
  description = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras eu magna sed orci interdum interdum. Ut id nibh non desc',
  totalPoints = 0,
}) {
  if (!show) {
    return null;
  }

  return (
    <div className="modal-backdrop" aria-modal="true" role="dialog">
      <div className="modal-content" tabIndex="-1">
        <button className="modal-close-button games-button-green" onClick={onClose} aria-label="Close modal">
          &times;
        </button>
        <div className="modal-body">
          {children}
          <p className="modal-description">{description}</p>
          <p className="total-points">
            <strong>Totaal punten:</strong> <span>{totalPoints}</span> {/* Added total points */}
          </p>
        </div>
        {/* <a className="claim-button games-button-green" href="#">
          Claim uw prijs
        </a> */}
      </div>
    </div>
  );
}

export default PrizeModal;
