import React, { Component } from 'react'
import { BrowserRouter, Route, Switch, withRouter } from "react-router-dom";

// Safari is the new IE
import smoothscroll from 'smoothscroll-polyfill';

//import 'primereact/resources/themes/nova/theme.css' <=== COMES FROM PHP AND IS LINKED DIRECTLY
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'
import './App.scss'

// Tiny CMS
import { TcmsContextProvider } from './contexts/TcmsContext'

import { FilterContextProvider } from './contexts/FilterContext'
import TcmsDialog from "./tcms/TcmsDialog"
import TcmsTweakPanel from "./tcms/TcmsTweakPanel"

// Pages
import Login from './pages/Login';
import Webshop from './pages/Webshop';
import Portal from "./pages/Portal";
import Info from "./pages/Info";
import BuyCredit from "./pages/BuyCredit";
import Order from "./pages/Order";
import PersonalData from "./pages/PersonalData";
import Pdp from "./pages/Pdp";
import Email from "./pages/Email";
import PageVirtualMarket from "./pages/PageVirtualMarket";
import Welcome from './pages/Welcome';
import ThankYou from './pages/ThankYou'
import CustomPage from './pages/CustomPage'
import GiveGift from './pages/GiveGift'
import ResetPassword from './pages/ResetPassword';
import GivePoints from './pages/GivePoints'
import Home from './pages/Home'

import MtgLogin from "./pages/mtg/MtgLogin";
import MtgWinkelModus from "./pages/mtg/MtgWinkelModus";
import HtmlHeadService from "./services/HtmlHeadService";
import Favorites from "./pages/Favorites";
import ShopModusWarning from './tcms/ShopModus/ShopModusWarning'
import Loader from './components/webshop/loader/Loader';
import Games from './components/webshop/games/games-container/Games';
import Leaderboard from './components/leaderboard/Leaderboard';
import FinalResult from './components/webshop/games/final-results/FinalResult';
import { Toaster } from 'react-hot-toast';


smoothscroll.polyfill();
const TweakPanel = withRouter((props) => <TcmsTweakPanel {...props} />);

class App extends Component {

  refTcmsDialog = React.createRef()
  state = {
    loading: true,
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({ loading: false });
    }, 1000);
  }



  render() {

    return (
      <React.Fragment>
        {/*Pass dialog to context*/}
        <TcmsContextProvider refTcmsDialog={this.refTcmsDialog}>
          <HtmlHeadService />
          <BrowserRouter basename={process.env.PUBLIC_URL}>
            {/*When we have a toke show warning*/}
            {localStorage.shopModusToken && <ShopModusWarning />}
            {this.state.loading ? <Loader /> : (
              <FilterContextProvider>
                {/*For tweaking stuff*/}
                <TweakPanel />
                <Toaster />
                {/*Need the dialog (tell the context I'm here) */}
                <TcmsDialog ref={this.refTcmsDialog} />
                <Switch>
                  {/* The new games route for testing - only used for demo at the moment */}
                  <Route key="015" exact path="/games" render={(props) => <Games {...props} />} />
                  {/*The order is important*/}
                  <Route key="235" exact path="/leaderboard" render={(props) => <Leaderboard {...props} />} />
                  <Route key="240" exact path="/final-result" render={(props) => <FinalResult {...props} />} />
                  <Route key="000" exact path="/" render={(props) => <Login {...props} />} />
                  <Route key="010" exact path="/mtg-login" render={(props) => <MtgLogin {...props} />} />
                  <Route key="020" exact path="/mtg-winkel-modus" render={(props) => <MtgWinkelModus {...props} />} />
                  <Route key="030" exact path="/:webshopslug" render={(props) => <Login {...props} />} />
                  <Route key="040" exact path="/:webshopslug/webshop/:filter?" render={(props) => <Webshop {...props} />} />
                  <Route key="050" exact path="/:webshopslug/portal" render={(props) => <Portal {...props} />} />
                  <Route key="060" exact path="/:webshopslug/hoe-werkt-het" render={(props) => <Info {...props} />} />
                  <Route key="065" exact path="/:webshopslug/credits-kopen" render={(props) => <BuyCredit {...props} />} />
                  <Route key="070" exact path="/:webshopslug/bestellingen" render={(props) => <Order {...props} />} />
                  <Route key="080" exact path="/:webshopslug/gegevens" render={(props) => <PersonalData {...props} />} />
                  <Route key="090" exact path="/:webshopslug/favorites" render={(props) => <Favorites {...props} />} />
                  <Route key="100" exact path="/:webshopslug/product/:product_id/:slug?"
                    render={(props) => <Pdp {...props} />} />
                  <Route key="110" exact path="/:webshopslug/virtueel"
                    render={(props) => <PageVirtualMarket {...props} />} />
                  <Route key="120" exact path="/:webshopslug/email"
                    render={(props) => <Email {...props} />} />
                  <Route key="130" exact path="/:webshopslug/welkom" render={(props) => <Welcome {...props} />} />
                  <Route key="140" exact path="/:webshopslug/bedankt" render={(props) => <ThankYou {...props} />} />
                  <Route key="150" exact path="/:webshopslug/lokaal" render={(props) => <CustomPage {...props} />} />
                  <Route key="160" exact path="/:webshopslug/duurzaam" render={(props) => <CustomPage {...props} />} />
                  <Route key="170" exact path="/:webshopslug/sociaal" render={(props) => <CustomPage {...props} />} />
                  <Route key="180" exact path="/:webshopslug/vitaal" render={(props) => <CustomPage {...props} />} />
                  <Route key="190" exact path="/:webshopslug/inclusief" render={(props) => <CustomPage {...props} />} />
                  <Route key="195" exact path="/:webshopslug/privacy" render={(props) => <CustomPage {...props} />} />
                  <Route key="200" path="/resetpassword/:token?" render={(props) => <ResetPassword {...props} />} />
                  <Route key="210" exact path="/:webshopslug/geef-je-geschenk-cadeau" render={(props) => <GiveGift {...props} />} />
                  <Route key="220" exact path="/:webshopslug/geef-punten" render={(props) => <GivePoints {...props} />} />
                  <Route key="225" exact path="/:webshopslug/plein" render={(props) => <Welcome {...props} />} />
                  <Route key="230" exact path="/:webshopslug/home" render={(props) => <Home {...props} />} />
                  <Route key="245" exact path="/:webshopslug/instructie-video" render={(props) => <CustomPage {...props} />} />
                </Switch>
              </FilterContextProvider>
            )}
          </BrowserRouter>
        </TcmsContextProvider>
      </React.Fragment>
    )
  }
}

export default App


