export const handleSpeech = (text, isSpeaking, setIsSpeaking) => {
  const synth = window.speechSynthesis;

  if (synth.speaking) {
    if (isSpeaking) {
      synth.cancel(); 
      setIsSpeaking(false);
    } else {
      synth.resume(); 
      setIsSpeaking(true);
    }
    return;
  }

  const utterances = text.split(". ").map((sentence) => sentence.trim()).filter(Boolean);

  let currentIndex = 0;

  const speakNext = () => {
    if (currentIndex < utterances.length) {
      const speech = new SpeechSynthesisUtterance(utterances[currentIndex]);
      speech.lang = "nl-NL";
      speech.rate = 0.9;
      speech.pitch = 1;

      speech.onend = () => {
        currentIndex++;
        speakNext();
      };

      speech.onerror = (e) => {
        console.error("Speech synthesis error:", e.error);
        setIsSpeaking(false);
      };

      synth.speak(speech);
      setIsSpeaking(true);
    } else {
      setIsSpeaking(false);
    }
  };

  synth.cancel();
  speakNext();
};
