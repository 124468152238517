import React, { useState, useEffect } from "react"
import { useHistory } from 'react-router-dom';
import confettiGif from "../assets/confetti.gif";
import "./GameResult.scss";
import natureSound from '../../../../sounds/nature.mp3';


const GameResult = ({ score, totalLetters, isBobMarleyQuiz = false, isFlagAndSportQuiz = false, isAnagramGame = false, isBeeGame = false, isKeywordQuiz = false, isWordSearch = false, onNextGame, goToFinalResult }) => {

  const [showConfetti, setShowConfetti] = useState(score > 5);
  const [fadeOut, setFadeOut] = useState(false);
  const history = useHistory();
  let sound;
  useEffect(() => {
    const playSound = () => {
      sound = new Audio(natureSound);
      sound.loop = true;
      sound.play().catch((error) => console.error('Failed to play sound:', error));
    };

    playSound();

    return () => {
      if (sound) {
        sound.pause();
        sound.currentTime = 0;
        sound = null;
      }
    };
  }, []);

  useEffect(() => {
    const fadeTimer = setTimeout(() => {
      setFadeOut(true);
    }, 4000);

    const hideTimer = setTimeout(() => {
      setShowConfetti(false);
    }, 5000);

    return () => {
      clearTimeout(fadeTimer);
      clearTimeout(hideTimer);
    };
  }, []);

  function getResultText() {
    if (isAnagramGame) {
      return (
        <div className="result-text">
          {score < 5 ? (
            <>
              <h2>Goed geprobeerd!</h2>
              <p>
                Je hebt <strong>{score}</strong> punten verzameld. Helaas heb je niet zo’n goede score, maar niet getreurd er komen nog nieuwe spellen om je score te verbeteren. 
              </p>
              <p>
                Bij het voltooien van deze opdracht heb je wel de <strong>Terrarium Steffi</strong> als geschenk toegevoegd aan je virtuele rugzak. Een mooi Terrarium met verse planten en een zelfvoorzienend ecosysteem.
              </p>
              <p>
                Wil je <strong>Terrarium Steffi</strong> niet alleen virtueel, maar ook in het echt? Ga dan na afloop naar de webshop om dit geschenk te selecteren, of kies hier een van de vele andere speciaal voor jou geselecteerde geschenken.
              </p>
              <p>Pak je rugzak en ga verder naar de volgende tussenstop!</p>
            </>
          ) : (
            <>
              <h2>Goed gedaan!</h2>
              <p>
              Je hebt <strong>{score}</strong> punten verzameld. Ga zo door en wie weet win jij één van de tien hoofdprijzen: een overnachting in een van de cabins van cabiner.com. 
              </p>
              <p>
              Mocht je uiteindelijk niet bij de beste 10 eindigen, dan ontvang je na het spelen alsnog een 10% korting op een reservering bij cabiner.com.
              </p>
              <p>
                Bij het voltooien van deze opdracht heb je de <strong>Terrarium Steffi</strong> als geschenk toegevoegd aan je virtuele rugzak. Een mooi Terrarium met verse planten en een zelfvoorzienend ecosysteem.
              </p>
              <p>
                Wil je <strong>Terrarium Steffi</strong> niet alleen virtueel, maar ook in het echt? Ga dan na afloop naar de webshop om dit geschenk te selecteren, of kies hier een van de vele andere speciaal voor jou geselecteerde geschenken.
              </p>
              <p>Pak je rugzak en ga vol vertrouwen verder naar de volgende tussenstop!</p>
            </>
          )}
        </div>
      );
    }

    if (isBobMarleyQuiz) {
      if (score <= 3) {
        return (
          <div className="result-text">
            <p>Goed geprobeerd!</p>
            <p>
             Je hebt <strong>{score}</strong> punten verzameld. Helaas heb je niet zo’n goede score, maar niet getreurd er komen nog nieuwe spellen om je score te verbeteren. 
            </p>
            <p>
              Bij het voltooien van deze opdracht heb je wel de de House of Marley-koptelefoon toegevoegd aan je virtuele rugzak. Deze stijlvolle hoofdtelefoon met superieure geluidskwaliteit welke is vervaardigd met duurzame materialen zoals FSC-gecertificeerd hout en gerecycled aluminium.
            </p>
            <p>
              Wil je de koptelefoon niet alleen virtueel, maar ook in het echt? Ga dan na afloop naar de webshop om dit geschenk te selecteren, of kies hier een van de vele andere speciaal voor jou geselecteerde geschenken.
            </p>
            <p>
              Pak je rugzak en ga verder naar de volgende tussenstop! <span className="emoji">🚶‍♂️</span>
            </p>
          </div>
        );
      }
      if (score >= 5) {
        return (
          <div className="result-text">
            <p>Fantastisch gedaan!</p>
            <p>
              Je hebt <strong>{score} punten</strong> verzameld. Ga zo door en wie weet win jij één van de tien hoofdprijzen: een overnachting in een van de cabins van cabiner.com. 
            </p>
            <p>
              Bij het voltooien van deze opdracht heb je de House of Marley-koptelefoon toegevoegd aan je virtuele rugzak. Deze stijlvolle hoofdtelefoon combineert superieure geluidskwaliteit met duurzame materialen, zoals FSC-gecertificeerd hout en gerecycled aluminium.
            </p>
            <p>
              Wil je de koptelefoon niet alleen virtueel, maar ook in het echt? Ga dan na afloop naar de webshop om dit geschenk te selecteren, of kies hier een van de vele andere speciaal voor jou geselecteerde geschenken.
              Pak je rugzak en zet koers naar de volgende tussenstop. Ga ervoor! <span className="emoji">🚶‍♂️</span>
            </p>
          </div>
        );
      }
    }

    if (isFlagAndSportQuiz) {
      if (score < 3) {
        return (
          <div className="result-text">
            <h2>Goed geprobeerd!</h2>
            <p>
             Je hebt <strong>{score}</strong> punten verzameld. Helaas heb je niet zo’n goede score, maar niet getreurd er komen nog nieuwe spellen om je score te verbeteren. 
            </p>
            <p>
              Door deze opdracht te voltooien, heb je de duurzame gerecyclede BE O Bottle waterfles, gemaakt van bioplastic uit suikerriet, toegevoegd aan je virtuele rugzak.
            </p>
            <p>
              Wil je de BE O Bottle niet alleen virtueel, maar ook in het echt? Ga dan na afloop naar de webshop om dit cadeau te selecteren, of kies een van de vele andere speciaal voor jou geselecteerde cadeaus.
            </p>
            <p>
              Maar voor nu, kop op, pak je rugzak en ga op weg naar de volgende stop!
            </p>
          </div>
        );
      } else {
        return (
          <div className="result-text">
            <h2>Fantastisch gedaan!</h2>
            <p>
              Je hebt maar liefst <strong>{score} punten</strong> verzameld. Ga zo door en wie weet win jij één van de tien hoofdprijzen: een overnachting in een van de cabins van cabiner.com. 
            </p>
            <p>
              Dat is een geweldige prestatie en je inspanningen worden beloond: door deze opdracht te voltooien heb je de duurzame gerecyclede BE O Bottle waterfles, gemaakt van bioplastic uit suikerriet, toegevoegd aan je virtuele rugzak.
            </p>
            <p>
              Wil je de BE O Bottle niet alleen virtueel, maar ook in het echt? Ga dan na afloop naar de webshop om dit cadeau te selecteren, of kies een van de vele andere speciaal voor jou geselecteerde cadeaus.
            </p>
            <p>
              Maar voor nu: geniet van je succes, pak je rugzak en zet koers naar de volgende stop!
            </p>
          </div>
        );
      }
    }

    if (isBeeGame) {
      if (score <= 5) {
        return (
          <div className="result-text">
            <p>Goed geprobeerd!</p>
            <p>
             Je hebt <strong>{score}</strong> punten verzameld. Helaas heb je niet zo’n goede score, maar niet getreurd er komen nog nieuwe spellen om je score te verbeteren. 
            </p>
            <p>
              Bij het voltooien van deze opdracht heb je het Capi Biodiversity Bee Hotel als cadeau toegevoegd aan je virtuele rugzak. Dit functionele en stijlvol vormgegeven bijenhotel biedt solitaire bijen een veilige nestplaats, waardoor je actief bijdraagt aan de biodiversiteit in je omgeving.
            </p>
            <p>
              Wil je het bijenhotel niet alleen virtueel, maar ook in het echt? Ga dan na afloop naar de webshop om dit cadeau te selecteren, of kies een van de vele andere speciaal voor jou geselecteerde cadeaus.
            </p>
            <p>
              Na een goede nachtrust gaan we snel door naar de volgende halte! <span className="emoji">🚶‍♂️</span>
            </p>
          </div>
        );
      }
      if (score >= 5) {
        return (
          <div className="result-text">
            <p>Fantastisch gedaan!</p>
            <p>
              Je hebt <strong>{score} punten</strong> verzameld. Een uitstekend resultaat, waarmee je echt laat zien wat je in huis hebt!
            </p>
            <p>
              Na het voltooien van deze opdracht heb je het Capi Biodiversity Bee Hotel als cadeau toegevoegd aan je virtuele rugzak. Dit functionele en stijlvol vormgegeven bijenhotel biedt solitaire bijen een veilige nestplaats en draagt ​​zo actief bij aan het bevorderen van de biodiversiteit in jouw omgeving.
            </p>
            <p>
              Wil je het bijenhotel niet alleen virtueel, maar ook in het echt? Ga dan na afloop naar de webshop om dit cadeau te selecteren, of kies een van de vele andere speciaal voor jou geselecteerde cadeaus.
              Na een korte rustpauze is het tijd om door te gaan naar de volgende halte. Succes! <span className="emoji">🚶‍♂️</span>
            </p>
          </div>
        );
      }
    }

    if (isKeywordQuiz) {
      if (score < 19) {
        return (
          <div className="result-text">
            <h2>Goed geprobeerd!</h2>
            <p>
              Je hebt <strong>{score}</strong> punten verzameld. Helaas heb je niet zo’n goede score, maar niet getreurd er komen nog nieuwe spellen om je score te verbeteren. 
            </p>
            <p>
              Bij het voltooien van deze opdracht heb je de Xtorm Fuel Series Solar Powerbank 10.000mAh 20W als geschenk toegevoegd aan je virtuele rugzak.
            </p>
            <p>
              Deze robuuste powerbank beschikt over een ingebouwd zonnepaneel, waardoor je onderweg je apparaten kunt opladen met zonne-energie.
            </p>
            <p>
              Wil je de Powerbank niet alleen virtueel, maar ook in het echt? Ga dan na afloop naar de webshop om dit geschenk te selecteren, of kies hier een van de vele andere speciaal voor jou geselecteerde geschenken.
            </p>
            <p>
              Na een goede rustpauze gaan we snel weer verder naar de volgende tussenstop.
            </p>
          </div>
        );
      } else {
        return (
          <div className="result-text">
            <h2>Fantastisch gedaan!</h2>
            <p>
              Je hebt <strong>{score}</strong> punten verzameld. Een topprestatie waarmee je jezelf echt hebt laten zien!
            </p>
            <p>
              Bij het voltooien van deze opdracht heb je de Xtorm Fuel Series Solar Powerbank 10.000mAh 20W als geschenk toegevoegd aan je virtuele rugzak.
            </p>
            <p>
              Deze robuuste powerbank, uitgerust met een ingebouwd zonnepaneel, maakt het mogelijk om onderweg je apparaten op te laden met duurzame zonne-energie.
            </p>
            <p>
              Wil je de Powerbank niet alleen virtueel, maar ook in het echt? Ga dan na afloop naar de webshop om dit geschenk te selecteren, of kies hier een van de vele andere speciaal voor jou geselecteerde geschenken.
            </p>
            <p>
              Neem een korte pauze en zet daarna koers naar de volgende tussenstop. Succes!
            </p>
          </div>
        );
      }
    }

    if (isWordSearch) {
      if (score === totalLetters) {
        return (
          <div>
            <p>
              Fantastisch gedaan! Je hebt {score} punten verzameld. Een topprestatie
              waarmee je jezelf echt hebt laten zien!
            </p>
            <p>Je bent hiermee aangekomen bij je eindbestemming!</p>
          </div>
        );
      } else if (score > 0 && score < totalLetters) {
        return (
          <div>
            <p>
              Goed geprobeerd! Je hebt {score} van de {totalLetters} letters correct
              ingevuld.
            </p>
            <p>Je bent hiermee aangekomen bij je eindbestemming!</p>
          </div>
        );
      } else {
        return (
          <div>
            <p>
              Goed geprobeerd! Je hebt {score} punten verzameld.
            </p>
            <p>Je bent hiermee aangekomen bij je eindbestemming!</p>
          </div>
        );
      }
    }

  }


  function getUnlockedText() {
    return `Je hebt ${score} van de ${totalLetters} letters ontgrendeld!`;
  }

  function getNextGameLabel() {
    if (isBobMarleyQuiz) return "Start spel 4";
    if (isFlagAndSportQuiz) return "Start spel 2";
    if (isAnagramGame) return "Start spel 3";
    if (isBeeGame) return "Start spel 5";
    if (isKeywordQuiz) return "Start spel 6";
    return "Volgende spel";
  }

  return (
    <div className="game-result-container">
      {showConfetti && (
        <div className={`confetti-overlay ${fadeOut ? "fade-out" : ""}`}>
          <img src={confettiGif} alt="Confetti" className="confetti-gif" />
        </div>
      )}
      <div className="game-result-content">
        <h1 className="result-title">Je resultaat</h1>

        <div className="result-summary">
          <p className="result-text">{getResultText()}</p>

          {isWordSearch && (
            <div className="unlocked-letters">
              <span role="img" aria-label="lock-icon" className="lock-icon">
                🗝️
              </span>
              <p className="unlocked-text">{getUnlockedText()}</p>
            </div>
          )}
        </div>
        {isWordSearch && (
          <>
            <button
              className="final-result-btn games-button-green"
              onClick={goToFinalResult}
            >
              Naar het eindscherm
            </button>
          </>
        )}
        {!isWordSearch && (
          <button
            className="restart-game-btn  games-button-green"
            onClick={onNextGame}
          >
            {getNextGameLabel()}
          </button>
        )}
        <button
          className="webshop-btn"
          onClick={() => (window.location.href = "/geschenk-2024/webshop")}
        >
          Naar de webshop
        </button>
      </div>
    </div>
  );
};

export default GameResult;