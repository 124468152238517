import {Card} from 'primereact/card'
import {Messages} from 'primereact/messages'
import {useContext, useRef} from 'react';
import {useEffect} from 'react';
import Tcms from "../../../tcms/Tcms";
import TcmsButton from "../../../tcms/Tags/TcmsButton";
import TcmsInputText from "../../../tcms/Tags/TcmsInputText";
import TcmsDiv from "../../../tcms/Tags/TcmsDiv";
import Helpdesk from '../../../components/webshop/helpdesk/Helpdesk';
import TcmsP from "../../../tcms/Tags/TcmsP";
import TcmsImg from '../../../tcms/Tags/TcmsImg';
import {useState} from 'react';
import {Checkbox} from 'primereact/checkbox';
import {TcmsContext} from '../../../contexts/TcmsContext';
import TcmsSpan from '../../../tcms/Tags/TcmsSpan';
import DialogForgotPassword from '../../../components/webshop/DialogForgotPassword';

export default function Login(props) {

  const {rootComponent} = props
  const context = useContext(TcmsContext)

  const refDialogForgotPassword = useRef();

  const [showPassword, setShowPassword] = useState(false);
  const [checked, setChecked] = useState(false);

  const heroImage = ["login_image_1.jpg", "login_image_2.jpg", "login_image_3.jpg"];
  const [activeIndex, setactiveIndex] = useState(0);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const checkShopMaintenance = (value) => {
    return !!value.includes("shop_maintenance");
  }

  useEffect(() => {

    const intervalId = setInterval(() => {
      if (activeIndex === 0) {
        setactiveIndex(1)
      }
      if (activeIndex === 1) {
        setactiveIndex(2)
      }
      if (activeIndex === 2) {
        setactiveIndex(0)
      }
    }, 20000)

    return () => clearInterval(intervalId);

  });

  const switchLanguage = () => {
    if (context.languageCode === "nl_nl") {
      context.setLanguageCode("en_us");
      rootComponent.state.locale = "en_us";
    } else {
      context.setLanguageCode("nl_nl");
      rootComponent.state.locale = "nl_nl";
    }
  };

  const defaultBgImage = context.mobile ? `/media/icons/.svg` : `/media/icons/.svg`
  const defaultBackgroundColor = 'rgb(255,255,255,1)'
  const defaultColor = 'rgb(0,0,0,1)'
  return (
    // A) Trick to be able to change the page background image of the root div
    <>
      <div className="p-grid page-wrapper page-wrapper-login">
        <div className='p-xl-6 p-md-6 p-col-12 col-left'>
          <div className="login">
            {rootComponent.context.editMode &&
              <div className="tcms-edit-wrapper">
                <div className='item'>
                  <div>
                    Langswitch class:
                  </div>
                  <div>
                    <Tcms>lang_class</Tcms>
                  </div>
                </div>
                <div className='item'>
                  <div>
                    Shop maintenance
                  </div>
                  <div>
                    <Tcms>shop_maintenance</Tcms>
                  </div>
                </div>
              </div>
            }
            <div className={"lang " + Tcms('lang_class')}>
              <a href="/" onClick={(e) => {
                e.preventDefault()
                switchLanguage()
              }}>
                <TcmsImg className="nav-top__langswitch-icon"
                         alt="Naar Engels"
                         src={`/media/icons/${context.languageCode === 'nl_nl' ? 'uk' : 'nl'}.svg`}/>
                <span>{context.languageCode === 'nl_nl' ? 'Vertaal naar engels' : 'Translate to dutch'}</span>
              </a>
            </div>
            {checkShopMaintenance(Tcms('shop_maintenance')) ?
              <div className="login-card-wrapper" style={{
                backgroundImage: `url('${Tcms(defaultBgImage)}')`,
                backgroundColor: Tcms(defaultBackgroundColor),
                color: Tcms(defaultColor)
              }}>
                {rootComponent.context.editMode &&
                  <TcmsDiv style={{
                    backgroundImage: `url('${defaultBgImage}')`,
                    backgroundColor: defaultBackgroundColor,
                    color: defaultColor
                  }} className="tcms-bg-image-picker"/>
                }
                <Card

                  footer={
                    <TcmsButton className="btn-login"
                                label={`${context.languageCode === 'nl_nl'
                                  ? (context.emailLogin ? 'Link opvragen' : 'Inloggen')
                                  : (context.emailLogin ? 'Request link' : 'Log in')
                                }`}
                                loading={rootComponent.state.loading}
                                onClick={() => rootComponent.submit(context.emailLogin,context.meta?.wso_id)}
                                style={{marginRight: '.25em'}}/>

                  }>
                  <div className="p-grid">
                    <div className="p-col-12">
                      <TcmsImg className="logo" style={{maxWidth: '100%'}} src="/media/images/nw_logo.png"></TcmsImg>
                      {/* <TcmsH4>Log in om jouw geschenken uit te zoeken</TcmsH4>
                    <TcmsH4>Log in to select your gifts</TcmsH4> */}
                    </div>
                    <TcmsP style={{
                      width: '100%',
                      textAlign: 'center',
                      color: '#666666',
                      padding: 0,
                      margin: 0
                    }}>_extra_login_message_</TcmsP>
                  </div>
                  {/* LOGIN */}
                  {context.emailLogin ?
                    // EMAIL LOGIN
                    <div className="p-grid">
                      <div className="p-col-12">
                        <div className="p-inputgroup">
                          <div className="p-inputgroup-addon">
                            <i className="pi pi-user"></i>
                          </div>
                          <TcmsInputText
                            value={rootComponent.state.user}
                            placeholder={`${context.languageCode === 'nl_nl' ? 'E-mailadres' : 'E-mail'}`}
                            onChange={(e) => {
                              rootComponent.setState({user: e.target.value})
                            }}
                            style={{width: '100%'}}/>
                        </div>
                        <Messages ref={(el) => rootComponent.messages = el}></Messages>
                      </div>
                    </div>
                    :
                    // USER PASSWORD LOGIN
                    <div className="p-grid">
                      <div className="p-col-12">
                        <div className="p-inputgroup">
                          <div className="p-inputgroup-addon">
                            <i className="pi pi-user"></i>
                          </div>
                          <TcmsInputText
                            value={rootComponent.state.user}
                            placeholder={`${context.languageCode === 'nl_nl' ? 'Gebruiker of E-mailadres' : 'Username or email'}`}
                            onChange={(e) => {
                              rootComponent.setState({user: e.target.value})
                            }}
                            style={{width: '100%'}}/>
                        </div>
                      </div>
                      <div className="p-col-12">
                        <div className="p-inputgroup">
                          <div className="p-inputgroup-addon">
                            <i className="pi pi-lock"></i>
                          </div>
                          <div className="p-password p-component p-inputwrapper p-inputwrapper-filled">
                            <input placeholder={`${context.languageCode === 'nl_nl' ? 'Wachtwoord' : 'Password'}`}
                                   className="p-inputtext p-component p-filled p-password-input"
                                   value={rootComponent.state.pass}
                                   onChange={(e) => {
                                     rootComponent.setState({pass: e.target.value})
                                   }}
                                   type={showPassword ? "text" : "password"}
                            />

                            <i onClick={handleClickShowPassword} className="pi pi-eye"></i>
                          </div>

                        </div>
                        <div className='p-inputgroup p-inputgroup-remember'>
                          <Checkbox onChange={e => setChecked(e.checked)} checked={checked} inputId="remember-me"
                                    value="yes"></Checkbox>
                          <label htmlFor="remember-me"
                                 className="p-checkbox-label">{context.languageCode === 'nl_nl' ? 'Onthoud mij' : 'Remember me'}</label>
                        </div>
                        <Messages ref={(el) => rootComponent.messages = el}></Messages>
                      </div>
                    </div>}
                  {/* /LOGIN */}
                </Card>

                {/*WACHTWOORD VERGETEN*/}
                {!context.emailLogin && <div className='p-col-12 p-justify-center p-flex'>
                  <div onClick={() => refDialogForgotPassword.current.open()}>
                    <TcmsSpan>{context.languageCode === 'nl_nl' ? 'Wachtwoord vergeten?' : 'Forgot your password?'}</TcmsSpan>
                  </div>
                </div>}

                <Card className="help-text">
                  <TcmsSpan>{'help_text' + (context.emailLogin ? '_email_login' : '')}</TcmsSpan>
                  <TcmsImg className="login_footer" style={{maxWidth: '100%'}}
                           src="/media/images/login_footer.png"></TcmsImg>
                </Card>
              </div>
              :
              <div className="login-card-wrapper" style={{
                backgroundImage: `url('${Tcms(defaultBgImage)}')`,
                backgroundColor: Tcms(defaultBackgroundColor),
                color: Tcms(defaultColor)
              }}>
                <Card>
                  <div className="p-grid">
                    <div className="p-col-12">
                      <TcmsImg className="logo" style={{maxWidth: '100%'}} src="/media/images/nw_logo.png"></TcmsImg>
                    </div>
                    {/* <Tcms>shop_maintenance</Tcms> */}
                    We zijn even offline voor onderhoud. Onze webshop wordt op dit moment verbeterd om u straks een
                    betere ervaring te bieden. Bedankt voor uw geduld, we zijn snel weer terug!
                  </div>

                </Card>
              </div>
            }
          </div>
        </div>
        <div className='p-xl-6 p-sm-6 p-col-12 col-right'>
          <div className="login-content">
            {/* <video autoPlay muted loop id="bg-video">
            <source src="./media/deer-in-snow.mp4" type="video/mp4"/>
          </video> */}
            <div className='hero-wrapper'>
              {rootComponent.context.editMode &&
                <div className='edit-images'>
                  <TcmsImg className={`tcms-bg-image-picker ${activeIndex === 0 ? 'active' : ''}`}
                           src={"/media/images/" + heroImage[0]}></TcmsImg>
                  <TcmsImg className={`tcms-bg-image-picker ${activeIndex === 1 ? 'active' : ''}`}
                           src={"/media/images/" + heroImage[1]}></TcmsImg>
                  <TcmsImg className={`tcms-bg-image-picker ${activeIndex === 2 ? 'active' : ''}`}
                           src={"/media/images/" + heroImage[2]}></TcmsImg>
                </div>
              }
              {rootComponent.context.webshopId !== 725 && rootComponent.context.webshopId !== 693 && rootComponent.context.webshopId !== 942 ?
                <>
                  <TcmsImg className={`bg-image ${activeIndex === 0 ? 'active' : ''}`}
                           src={"/media/images/" + heroImage[0]}></TcmsImg>
                  <TcmsImg className={`bg-image ${activeIndex === 1 ? 'active' : ''}`}
                           src={"/media/images/" + heroImage[1]}></TcmsImg>
                  <TcmsImg className={`bg-image ${activeIndex === 2 ? 'active' : ''}`}
                           src={"/media/images/" + heroImage[2]}></TcmsImg>
                  <div className='bullets'>
                    <span onClick={(e) => {
                      e.preventDefault()
                      setactiveIndex(0)
                    }} className={`bullet ${activeIndex === 0 ? 'active' : ''}`}></span>
                    <span onClick={(e) => {
                      e.preventDefault()
                      setactiveIndex(1)
                    }} className={`bullet ${activeIndex === 1 ? 'active' : ''}`}></span>
                    <span onClick={(e) => {
                      e.preventDefault()
                      setactiveIndex(2)
                    }} className={`bullet ${activeIndex === 2 ? 'active' : ''}`}></span>
                  </div>

                </>
                :
                <>
                  <TcmsImg className={`bg-image active`} src={"/media/images/" + heroImage[0]}></TcmsImg>
                </>
              }
            </div>

            {/* B) Trick to be able to change the page background image */}

          </div>
        </div>

        <DialogForgotPassword
          ref={refDialogForgotPassword}
        />
        <Helpdesk/>
      </div>
    </>
  )
}



