import React, { useState, useEffect, useContext, useRef } from "react";
import data from './guessGameData.json';
import GameIntro from '../game-intro/GameIntro';
import GameResult from '../game-result/GameResult';
import "./FlagAndSportQuiz.scss";
import VideoPlaceholder from "../video-placeholder/VideoPlaceholder";
import { storeGameData, getGameScoresByUser, updateGameData, deleteGameData } from "../services/gameProgressService";
import { useHistory } from 'react-router-dom';
import { TcmsContext } from '../../../../contexts/TcmsContext';
import jwtDecode from 'jwt-decode';
import { toast } from 'react-hot-toast';
import gamesTransitionVideo from '../assets/games_transition.mp4';
import pingSound from '../../../../sounds/ping.mp3';
import buzzSound from '../../../../sounds/softbuzz.mp3';
import sportImage from '../assets/sport.png';
import axios from 'axios';
import flagsImage from '../assets/flags2.png';

const FlagAndSportQuiz = ({ startGame, setStartGame, setIsMusicMuted, setIsIntro, fetchScore }) => {
  const lastAnswerRef = useRef(null); 
  const { setJwtForGames } = useContext(TcmsContext);
  const gameIntroText = {
    title: 'Raad de vlag!',
    description: `Na een wandeling bereik je tussenstop 1. Deze tussenstop is gerelateerd aan de BE O Bottle die verkrijgbaar is in de webshop. De fles is een stuk milieuvriendelijker dan de vlaggen die je verderop tegenkomt.<br/><br/>
    <strong>Doel van dit spel:</strong> Raad zoveel mogelijk vlaggen en olympische sporten binnen de tijd.<br/>
    <strong>Thema: </strong> Terugblik 2024<br/><br/>
    Vul je rugzak met punten en kom een stap dichterbij die unieke overnachting in de natuur! Veel succes!`,
    instruction: 'Raad zoveel mogelijk vlaggen en Olympische sporten binnen de tijdslimiet',
    showMedals: true
  };

  const initialGuesses = Array(10).fill({ guess: '', isCorrect: null, correctAnswer: '' });
  const [inputValue, setInputValue] = useState('');
  const [guessList, setGuessList] = useState(initialGuesses);
  const [guessIndex, setGuessIndex] = useState(0);
  const [timeLeft, setTimeLeft] = useState(45);
  const [gameFinished, setGameFinished] = useState(false);
  const [correctAnswers, setCorrectAnswers] = useState([]);
  const [levelImages, setLevelImages] = useState([]);
  const [gameStarted, setGameStarted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [phase, setPhase] = useState(1);
  const history = useHistory();
  const [TotalcorrectGuessesCount, setTotalcorrectGuessesCount] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const [lastImage, setLastImage] = useState(null);
  const [countdown, setCountdown] = useState(10);
  const [showCountdown, setShowCountdown] = useState(false);
  const [countdownStarted, setCountdownStarted] = useState(false);

  const beginGame = () => {
    setGameStarted(true);
    setIsIntro(false);
    startGameCountdown();
  };

  const gameId = 1;
  const [userId, setUserId] = useState('');
  const [shopId, setShopId] = useState('');
  const [existingGameId, setExistingGameId] = useState(null);



  const startNextPhaseCountdown = () => {
    setShowCountdown(true);
    setCountdownStarted(true);
    setCountdown(10);
    setLastImage(levelImages[guessIndex]);

    setTimeout(() => {
      setShowCountdown(false);
      setCountdownStarted(false);
      setPhase(2);
      setGuessList(
        Array(10).fill({ guess: "", isCorrect: null, correctAnswer: "" })
      );
      setGuessIndex(0);
      setTimeLeft(45);
      setLastImage(null);
    }, 10000);
  };

  const startGameCountdown = () => {
    setShowCountdown(true);
    setCountdownStarted(true);

    setTimeout(() => {
      setShowCountdown(false);
      setGameStarted(true);
    }, 10000);
  };

  useEffect(() => {
    const storedJwtToken = sessionStorage.getItem('jwt_token');
    if (storedJwtToken) {
      axios.defaults.headers.common['MtgJwt'] = storedJwtToken;
      const tokenData = jwtDecode(storedJwtToken);
      setUserId(tokenData?.usr?.uid);
      setShopId(tokenData?.usr?.wid);
      setJwtForGames(storedJwtToken);
    }
  }, [setJwtForGames]);

    // Fetch existing game progress to check for existing entries
    useEffect(() => {
      const fetchGameProgress = async () => {
        try {
          const progress = await getGameScoresByUser(userId);
          const gameEntry = progress.result.find(
            (entry) => entry.game_id === gameId && entry.webshoporder_id === shopId
          );
          if (gameEntry) {
            setExistingGameId(gameEntry.id);
          }
        } catch (error) {
          console.error('Error fetching game progress:', error.message);
        }
      };
  
      if (userId) {
        fetchGameProgress();
      }
    }, [userId, shopId]);

  // Save game progress when quiz is finished
  useEffect(() => {
    if (gameFinished) {
      const saveProgress = async () => {
        try {
          if (existingGameId) {
            // Update the existing entry
            await updateGameData(existingGameId, TotalcorrectGuessesCount);
          } else {
            // Create a new entry
            const response = await storeGameData(userId, gameId, shopId, TotalcorrectGuessesCount);
            setExistingGameId(response.result.id);
          }
          if (fetchScore) {
            fetchScore(true);
          }
        } catch (error) {
          console.error('Error saving game progress:', error.message);
        }
      };
      saveProgress();
    }
  }, [gameFinished, existingGameId, userId, gameId, shopId, TotalcorrectGuessesCount]);

  const handleBeforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = '';
    toast('Je kunt het spel niet opnieuw spelen als je de pagina verlaat!', {
      position: 'bottom-center',
      duration: 5000,
      style: {
        background: '#facc15',
        color: '#000',
      },
    });
  
    const saveProgress = async () => {
      try {
        if (existingGameId) {
          await updateGameData(existingGameId, TotalcorrectGuessesCount);
        } else {
          const response = await storeGameData(userId, gameId, shopId, TotalcorrectGuessesCount);
          setExistingGameId(response.result.id);
        }
      } catch (error) {
        console.error('Error saving progress on exit:', error.message);
      }
    };
    saveProgress();
  };
  
  useEffect(() => {
    if (gameStarted && !gameFinished) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }
  
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [gameStarted, gameFinished, guessList, existingGameId, userId, gameId, shopId, TotalcorrectGuessesCount]);
  
  useEffect(() => {
    if (gameFinished) {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }
  }, [gameFinished]);
  

  const initializeGameData = () => {
    const filteredData = phase === 1
      ? data.filter(item => item.pictureUrl.includes('flags'))
      : data.filter(item => item.pictureUrl.includes('sports'));

    const shuffledData = shuffleArray(filteredData);
    const shuffledAnswers = shuffledData.map(item => item.name);
    const shuffledImages = shuffledData.map(item => item.pictureUrl);
    setCorrectAnswers(shuffledAnswers);
    setLevelImages(shuffledImages);
  };

  useEffect(() => {
    initializeGameData();
  }, [phase]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  
  useEffect(() => {
    if (showCountdown && countdownStarted) {
      const timer = setInterval(() => {
        setCountdown((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            setShowCountdown(false);
            setCountdownStarted(false);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [countdown, showCountdown, countdownStarted]);
  
  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array.slice(0, 10);
  }
  function levenshteinDistance(a, b, penaltyWeight = 0.7) {
    const matrix = [];
    const vowels = new Set(['a', 'e', 'i', 'o', 'u']);
    const commonSubstitutions = new Map([
      ['c', 'k'],
      ['s', 'z'],
      ['q', 'k'],
      ['p', 'b'],
      ['d', 't']
    ]);

    for (let i = 0; i <= b.length; i++) {
      matrix[i] = [i];
    }

    for (let j = 0; j <= a.length; j++) {
      matrix[0][j] = j;
    }

    for (let i = 1; i <= b.length; i++) {
      for (let j = 1; j <= a.length; j++) {
        const charA = a.charAt(j - 1);
        const charB = b.charAt(i - 1);

        if (charA === charB) {
          matrix[i][j] = matrix[i - 1][j - 1];
        } else {
          let substitutionCost = 1;

          if (vowels.has(charA) && vowels.has(charB)) {
            substitutionCost = 0.3;
          }
          else if (commonSubstitutions.get(charA) === charB || commonSubstitutions.get(charB) === charA) {
            substitutionCost = 0.5;
          }

          substitutionCost *= penaltyWeight;

          matrix[i][j] = Math.min(
            matrix[i - 1][j - 1] + substitutionCost,
            matrix[i][j - 1] + 1 * penaltyWeight,
            matrix[i - 1][j] + 1 * penaltyWeight
          );
        }
      }
    }

    return matrix[b.length][a.length];
  }

  const handleSubmit = () => {
    if (isProcessing || gameFinished || timeLeft <= 0) {
        return;
    }

    setIsProcessing(true);

    if (inputValue.trim()) {
        const updatedGuesses = [...guessList];
        const nextEmptyIndex = updatedGuesses.findIndex(guessObj => guessObj.guess === '');

        if (nextEmptyIndex !== -1) {
            const correctAnswer = correctAnswers[nextEmptyIndex];
            const currentDataItem = data.find(item => item.name === correctAnswer);
            const acceptedAnswers = currentDataItem?.acceptedAnswers || [];
            const matchedSynonym = acceptedAnswers.find(answer =>
                levenshteinDistance(inputValue.toLowerCase(), answer.toLowerCase()) <= 1
            );

            const isCorrect =
                levenshteinDistance(inputValue.toLowerCase(), correctAnswer.toLowerCase()) <= 1 || !!matchedSynonym;

            updatedGuesses[nextEmptyIndex] = {
                guess: inputValue,
                isCorrect,
                correctAnswer,
                matchedSynonym: isCorrect ? matchedSynonym : null,
            };

            setGuessList(updatedGuesses);
            setGuessIndex(guessIndex + 1);
            setInputValue('');
            setTimeLeft(45);

            if (isCorrect) {
                setTotalcorrectGuessesCount(prevCount => prevCount + 1);
                new Audio(pingSound).play();
            } else {
                new Audio(buzzSound).play();
            }

            if (guessIndex + 1 === correctAnswers.length) {
              setLastImage(levelImages[guessIndex])
                setTimeout(() => {
                    if (phase === 1) {
                        startNextPhaseCountdown();
                        setPhase(2);
                        setGuessList(Array(10).fill({ guess: '', isCorrect: null, correctAnswer: '' }));
                        setGuessIndex(0);
                        setTimeLeft(45);
                    } else {
                        setGameFinished(true);
                    }
                }, 1000);
            }
        }
    }
      setTimeout(() => {
        if (lastAnswerRef.current) {
            lastAnswerRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      }, 300);

    setTimeout(() => setIsProcessing(false), 300);
};

  const handleFinishGame = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setStartGame(false);
      history.push('/games?game=2');
    }, 5000);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit();
    }
  };

  const getCurrentFlagInfo = () => {
    if (!levelImages[guessIndex]) return null;
    const currentDataItem = data.find(
      (item) => item.pictureUrl === levelImages[guessIndex] && item.pictureUrl.includes('flags')
    );
    return currentDataItem ? currentDataItem.info : null;
  };

  useEffect(() => {
    if (gameStarted && !gameFinished && !showCountdown && timeLeft > 0) {
      const timerId = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timerId);
    } else if (timeLeft === 0 && gameStarted && !gameFinished) {
      const updatedGuesses = [...guessList];
      if (guessIndex < correctAnswers.length) {
        updatedGuesses[guessIndex] = {
          guess: 'Timed out',
          isCorrect: false,
          correctAnswer: correctAnswers[guessIndex]
        };
        setGuessList(updatedGuesses);
        setGuessIndex(guessIndex + 1);
        setInputValue(''); 
        setTimeLeft(45);
        if (guessIndex + 1 === correctAnswers.length) {
          setGuessIndex(correctAnswers.length - 1);
          setTimeout(() => {
            if (phase === 1) {
              setPhase(2);
              startNextPhaseCountdown();
              setGuessList(Array(10).fill({ guess: '', isCorrect: null, correctAnswer: '' }));
              setGuessIndex(0);
              setTimeLeft(45);
            } else {
              setGameFinished(true);
            }
          }, 500);
        }
      }
    }
  }, [timeLeft, guessIndex, guessList, gameFinished, gameStarted, phase, showCountdown]);

  if (!gameStarted && startGame) {
    return (
      <>
        <GameIntro {...gameIntroText} onStart={beginGame} setIsMusicMuted={setIsMusicMuted} />
      </>
    );
  }

  if (loading) {
    return (
      <div className="game-loader">
        <video
          src={gamesTransitionVideo}
          autoPlay
          muted
          onEnded={() => setLoading(false)}
          className="transition-video"
        />
      </div>
    );
  }

  if (gameFinished) {
    return (
      <GameResult
        score={TotalcorrectGuessesCount}
        totalQuestions={20}
        isFlagAndSportQuiz={true}
        onNextGame={handleFinishGame}
      />
    );
  }

  return (
    <div className={`game-screen ${!startGame ? "game-screen-video" : ""}`}>
      {startGame && (
        <div className="top-right-wooden-sign-container">
          <p>
            {phase === 1
              ? "Herken de vlaggen, test je kennis en scoor zoveel mogelijk punten binnen de tijd!"
              : "Raad de olympische sporten van de zomer- en winterspelen"}
          </p>
        </div>
      )}
      {/* {showPhaseTransition && (
        <div className="games-transition-screen">
          <img
            src={phase === 1 ? flagsImage : sportImage}
            alt={phase === 1 ? "Flags Transition" : "Sports Transition"}
            className="games-transition-image"
          />
          <h3>
            {phase === 1
              ? "Raad nu de vlaggen van de meest vervuilende landen van Europa"
              : "Raad nu de olympische winter en zomerspelen"}
          </h3>
        </div>
      )} */}
      <div className='highlight-current-game'> 1 </div>
      {!startGame ? (
        <VideoPlaceholder setIsMusicMuted={setIsMusicMuted} />
      ) : (
        <div className="game-two-content">
          <div className="game-two-section">
            {showCountdown && (
              <div className="countdownOverlay-left">
                <div className="countdownOverlay-text">
                  <h2>
                    {phase === 1
                      ? "Raad de vlaggen"
                      : "Raad de Olympische sporten"}
                  </h2>
                  <img
                    src={phase === 1 ? flagsImage : sportImage}
                    alt={
                      phase === 1 ? "Flags Illustration" : "Sports Illustration"
                    }
                    className="countdownOverlay-image"
                  />
                </div>
              </div>
            )}
            <div className="title-and-text">
              <h2>{phase === 1 ? "Vlaggen Herkenning" : "Olympische Sporten Herkenning"}</h2>
              <div className="game-two-text">
                <p> {phase === 1 ? "De vraag boven elke afbeelding is: Welk landen zijn dit? (45 seconden per vlag)" : "Welke Olympische sport is dit? (45 seconden per sport)"}</p>
              </div>
            </div>
            <div className="game-guesses">
              <ol>
                {guessList.map((guessObj, index) => {
                  const currentDataItem = data.find(
                    (item) => item.name === guessObj.correctAnswer
                  );
                  const additionalInfo = null;

                  return (
                    <li
                      className={`list-guesses ${
                        guessObj.isCorrect
                          ? "correct"
                          : guessObj.isCorrect === false
                          ? "incorrect"
                          : ""
                      }`}
                      key={index}
                      ref={index === guessIndex - 1 ? lastAnswerRef : null}
                    >
                      {guessObj.guess || ""}
                      {guessObj.isCorrect && (
                        <span style={{ color: 'green' }}>
                          {" "}
                          ✔️ {guessObj.correctAnswer}
                          {guessObj.matchedSynonym && ` (${guessObj.matchedSynonym})`}
                        </span>
                      )}
                      {guessObj.isCorrect === false && (
                        <span style={{ color: "red" }}>
                          {" "}
                          ❌ {guessObj.correctAnswer}
                        </span>
                      )}
                      {additionalInfo && (
                        <p className="additional-info">{additionalInfo}</p>
                      )}
                    </li>
                  );
                })}
              </ol>
            </div>
          </div>
          <div className="game-two-image-section">
            {showCountdown && (
              <div className="games-countdown-overlay timer-overlay">
                <svg className="games-progress-circle" viewBox="0 0 100 100">
                  <defs>
                    <linearGradient
                      id="green-gradient"
                      x1="0%"
                      y1="0%"
                      x2="100%"
                      y2="100%"
                    >
                      <stop offset="0%" stop-color="#67f16a" />
                      <stop offset="50%" stop-color="#00a651" />
                      <stop offset="100%" stop-color="#0b8d37" />
                    </linearGradient>
                  </defs>
                  <circle
                    className="games-progress-background"
                    cx="50"
                    cy="50"
                    r="45"
                    strokeWidth="10"
                  />
                  <circle
                    className="games-progress-bar"
                    cx="50"
                    cy="50"
                    r="45"
                    strokeWidth="10"
                    style={{
                      strokeDashoffset: `${283 - (283 * countdown) / 10}`,
                    }}
                  />
                </svg>
                <div className="countdown-text">{countdown}</div>
              </div>
            )}
            <img
              className="game-image"
              src={levelImages[guessIndex] || lastImage}
              alt="Game Image"
              style={{
                width: "100%",
                height: "275px",
                marginBottom: "10px",
                objectFit: "contain",
              }}
            />
            <div className="timer-progress-bar">
              <div className="progress" style={{ width: `${(timeLeft / 45) * 100}%` }}></div>
            </div>

            {/* Info section for the current flag */}
            {phase === 1 && (
              <div className="info-container">
                <p className="info-text">
                  {getCurrentFlagInfo() ? getCurrentFlagInfo() : ' '}
                </p>
              </div>
            )}
            <div className="input-container">
              <input
                type="text"
                className="input-field"
                placeholder="Typ je antwoord..."
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                disabled={gameFinished || showCountdown} 
                maxLength={25}
              />
              <button className="submit-btn" onClick={handleSubmit} disabled={gameFinished || showCountdown}>
                &#9654;
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FlagAndSportQuiz;
