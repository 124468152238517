import {useContext, useRef} from "react";
import VirtualMarket from "../../../components/virtual/VirtualMarket";
import VirtualMarketMenu from "../../../components/virtual/VirtualMarketMenu";
import VirtualMarketPubquizCountdown from "../../../components/virtual/VirtualMarketPubquizCountdown";
import VirtualDialogWebshopCategory from "../../../components/virtual/dialogs/VirtualDialogWebshopCategory";
import {TcmsContext} from "../../../contexts/TcmsContext";
import WidgetPopup from "../../../widgets/WidgetPopup";

export default function SkinVirtualMarket() {

  const context = useContext(TcmsContext)
  const refVirtualDialogWebshopCategory = useRef()
  const refWidgetPopup = useRef(null)

  const onPanoClick = (value) => {
    let fullWidthWidgets = ["borrelen","pub-quiz"]
    let values = value.split('_')
    let width = (fullWidthWidgets.includes(values[1])) ? '80vw' : '50vw'

    if (values[0] === 'wi') {
      // Open a widget
      refWidgetPopup.current.open(values[1], width)
    } else if (values[0] === 'pc') {
      // Open a category
      const hoofdcat_id = parseInt(values[1])
      const subcat_id = parseInt(values[2])

      refVirtualDialogWebshopCategory.current.open(hoofdcat_id, subcat_id)
    }
  }

  return (
    <>
      <VirtualDialogWebshopCategory
        ref={refVirtualDialogWebshopCategory}/>

      <WidgetPopup ref={refWidgetPopup}/>

      <VirtualMarketPubquizCountdown pubquiz={context.pubquiz}/>

      <div style={{display: 'flex'}}>
        <div style={{width: 'auto'}}>
          <VirtualMarketMenu/>
        </div>
        <div style={{height: '100vh', width: '100%'}}>
          <VirtualMarket
            vmName={context.virtualMarketSlug}
            onPanoClick={value => onPanoClick(value)}/>
        </div>

      </div>
    </>
  )
}


