import React, { useState, useEffect, useContext } from "react";
import VideoPlaceholder from "../video-placeholder/VideoPlaceholder";
import GameIntro from '../game-intro/GameIntro';
import GameResult from '../game-result/GameResult';
import { useHistory } from 'react-router-dom';
import "./AnagramGame.scss";
import { storeGameData, getGameScoresByUser, updateGameData, deleteGameData } from "../services/gameProgressService";
import { TcmsContext } from '../../../../contexts/TcmsContext';
import jwtDecode from 'jwt-decode';
import { toast } from 'react-hot-toast';
import gamesTransitionVideo from '../assets/games_transition.mp4';
import pingSound from '../../../../sounds/ping.mp3';
import buzzSound from '../../../../sounds/softbuzz.mp3';
import axios from "axios";

const AnagramGame = ({ startGame, setStartGame, setIsMusicMuted, setIsIntro, fetchScore }) => {
  const [originalAnagrams, setOriginalAnagrams] = useState([
    { scrambled: 'latPn', correct: 'Plant' },
    { scrambled: 'Rireiv', correct: 'Rivier' },
    { scrambled: 'kweuvelSon', correct: 'Sneeuwvlok' },
    { scrambled: 'rGeno', correct: 'Groen' },
    { scrambled: 'nadZ', correct: 'Zand' },
    { scrambled: 'snaelortZan', correct: 'Zonnestraal' },
    { scrambled: 'taerW', correct: 'Water' },
    { scrambled: 'sarG', correct: 'Gras' },
    { scrambled: 'larevWat', correct: 'Waterval' },
    { scrambled: 'geloV', correct: 'Vogel' },
    { scrambled: 'sniVes', correct: 'Vissen' },
    { scrambled: 'nonrandesogZ', correct: 'Zonsonderang' },
    { scrambled: 'elmoB', correct: 'Bloem' },
    { scrambled: 'rdelinV', correct: 'Vlinder' },
    { scrambled: 'waDu', correct: 'Dauw' },
    { scrambled: 'ralelietWe', correct: 'Waterlelie' },
    { scrambled: 'delenaLv', correct: 'Lavendel' },
    { scrambled: 'nlGeov', correct: 'Golven' },
    { scrambled: 'gonboeRge', correct: 'Regenboog' },
    { scrambled: 'zerktuniosR', correct: 'Rozenstruik' },
    { scrambled: 'dranSt', correct: 'Strand' },
    { scrambled: 'elndearB', correct: 'Bladeren' },
    { scrambled: 'Daschilunapnd', correct: 'Duinlandschap' },
    { scrambled: 'nMaa', correct: 'Maan' },
    { scrambled: 'eĲsbrg', correct: 'Ĳsberg' },
    { scrambled: 'anecaO', correct: 'Oceaan' },
    { scrambled: 'dareA', correct: 'Aarde' },
    { scrambled: 'senoMs', correct: 'Mossen' },
    { scrambled: 'inHong', correct: 'Honing' },
    { scrambled: 'kankeT', correct: 'Takken' },
    { scrambled: 'nemeloB', correct: 'Bloemen' },
    { scrambled: 'jivreV', correct: 'Vijver' },
    { scrambled: 'genBer', correct: 'Bergen' },
    { scrambled: 'zieKel', correct: 'Kiezel' },
    { scrambled: 'uktriS', correct: 'Struik' },
    { scrambled: 'Alhignedam', correct: 'Ademhaling' },
    { scrambled: 'dBa', correct: 'Bad' },
    { scrambled: 'genBer', correct: 'Bergen' },
    { scrambled: 'linChel', correct: 'Chillen' },
    { scrambled: 'rnDome', correct: 'Dromen' },
    { scrambled: 'zeLne', correct: 'Lezen' },
    { scrambled: 'Lenerui', correct: 'Luieren' },
    { scrambled: 'ssgMaae', correct: 'Massage' },
    { scrambled: 'ediMatiet', correct: 'Meditatie' },
    { scrambled: 'fundelMiss', correct: 'Mindfulness' },
    { scrambled: 'zeiuMk', correct: 'Muziek' },
    { scrambled: 'xalRe', correct: 'Relax' },
    { scrambled: 'sRut', correct: 'Rust' },
    { scrambled: 'aaunS', correct: 'Sauna' },
    { scrambled: 'plaSa', correct: 'Slaap' },
    { scrambled: 'pSa', correct: 'Spa' },
    { scrambled: 'tiStle', correct: 'Stilte' },
    { scrambled: 'entUtisur', correct: 'Uitrusten' },
    { scrambled: 'taknaeVi', correct: 'Vakantie' },
    { scrambled: 'jivreV', correct: 'Vijver' },
    { scrambled: 'geloVs', correct: 'Vogels' },
    { scrambled: 'Vridjehi', correct: 'Vrijheid' },
    { scrambled: 'gYao', correct: 'Yoga' }
  ]);

  const [anagrams, setAnagrams] = useState([...originalAnagrams]);

  const gameIntroText = {
    title: 'Welkom bij Spel 2: Anagrammen 🔠',
    description: `Na een rustgevende tocht ben je aangekomen op de 2e tussenstop. Tijdens de tocht is je routekaart gekreukeld en staan alle woorden over de natuur gehusseld. Ontcijfer de gehusselde woorden en verdien punten. Het spel is gerelateerd aan het ecosysteem erlenmeyer, die u kunt kiezen in de webshop! 
    Succes met puzzelen!`,
    instruction: 'Maak je klaar en los de anagrammen op! Klik op de letters in de juiste volgorde om het woord te vormen of skip als je vastzit! 🔄',
    showMedals: false
  };

  const { setJwtForGames } = useContext(TcmsContext);
  const [currentAnagram, setCurrentAnagram] = useState(anagrams[0]);
  const [scrambledLetters, setScrambledLetters] = useState([]);
  const [selectedLetters, setSelectedLetters] = useState([]);
  const [correctCount, setCorrectCount] = useState(0);
  const [progress, setProgress] = useState(100);
  const [timeLeft, setTimeLeft] = useState(190);
  const [gameStatus, setGameStatus] = useState('playing');
  const [wrongGuess, setWrongGuess] = useState(false);
  const [gameStarted, setGameStarted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [gameFinished, setGameFinished] = useState(false);
  const history = useHistory();
  const [usedLetters, setUsedLetters] = useState([]);
  const [countdown, setCountdown] = useState(10);
  const [showCountdown, setShowCountdown] = useState(true);
  const [countdownStarted, setCountdownStarted] = useState(false);
  const [showAnagramList, setShowAnagramList] = useState(false);
  const gameId = 2;
  const [userId, setUserId] = useState('');
  const [shopId, setShopId] = useState('');
  const [existingGameId, setExistingGameId] = useState(null);
  const [isOptionBoxDisabled, setIsOptionBoxDisabled] = useState(false);

  useEffect(() => {
    const storedJwtToken = sessionStorage.getItem('jwt_token');
    if (storedJwtToken) {
      axios.defaults.headers.common['MtgJwt'] = storedJwtToken;

      const tokenData = jwtDecode(storedJwtToken);
      setUserId(tokenData?.usr?.uid);
      setShopId(tokenData?.usr?.wid);
      setJwtForGames(storedJwtToken);
    }
  }, [setJwtForGames]);

  // Fetch existing game progress to check for existing entries
  useEffect(() => {
    const fetchGameProgress = async () => {
      try {
        const progress = await getGameScoresByUser(userId);
        const gameEntry = progress.result.find(
          (entry) => entry.game_id === gameId && entry.webshoporder_id === shopId
        );
        if (gameEntry) {
          setExistingGameId(gameEntry.id);
        }
      } catch (error) {
        console.error('Error fetching game progress:', error.message);
      }
    };
    if (userId) {
      fetchGameProgress();
    }
  }, [userId, shopId]);

  useEffect(() => {
    if (startGame && showCountdown && countdownStarted) {
      const timer = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);

      if (countdown === 0) {
        clearInterval(timer);
        setShowCountdown(false);
        setCountdownStarted(false);
        setGameStarted(true);
        setGameStatus('playing');
      }

      return () => clearInterval(timer);
    }
  }, [countdown, showCountdown, startGame, countdownStarted]);

  const beginGame = () => {
    setGameStarted(true);
    setIsIntro(false);
    setGameStatus('playing');
    setCountdownStarted(true);
  };

  useEffect(() => {
    if (timeLeft === 0) return;
    setScrambledLetters([...currentAnagram.scrambled.split('')]);
  }, [currentAnagram]);

  useEffect(() => {
    if (gameStarted && timeLeft > 0 && gameStatus === 'playing') {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
        setProgress((timeLeft / 180) * 100);
      }, 1000);
      return () => clearInterval(timer);
    } else if (timeLeft <= 0) {
      setGameStatus('lost');
      setGameFinished(true);
    }
  }, [timeLeft, gameStarted, gameStatus]);

  const handleLetterClick = (letter, index) => {
    if (
      gameStatus === 'playing' &&
      selectedLetters.length < currentAnagram.correct.length &&
      !usedLetters.some(item => item.index === index)
    ) {
      const updatedSelectedLetters = [...selectedLetters, letter];
      const updatedUsedLetters = [...usedLetters, { letter, index }];
      setSelectedLetters(updatedSelectedLetters);
      setUsedLetters(updatedUsedLetters);
      if (updatedSelectedLetters.length === currentAnagram.correct.length) {
        if (updatedSelectedLetters.join('') === currentAnagram.correct) {
          setCorrectCount(correctCount + 1);
          new Audio(pingSound).play();
          if (anagrams.length === 1) {
            setGameFinished(true);
            setGameStatus('completed');
          } else {
            const nextAnagrams = anagrams.slice(1);
            setAnagrams(nextAnagrams);
            setCurrentAnagram(nextAnagrams[0]);
            setSelectedLetters([]);
            setUsedLetters([]);
          }
        } else {
          setWrongGuess(true);
          new Audio(buzzSound).play();
          const updatedAnagrams = [...anagrams.slice(1), anagrams[0]];
          setAnagrams(updatedAnagrams);
          setCurrentAnagram(updatedAnagrams[0]);
          setSelectedLetters([]);
          setUsedLetters([]);
          setTimeout(() => setWrongGuess(false), 1000);
        }
      }
    }
  };

  const handleSkip = () => {
    if (anagrams.length > 1) {
      const updatedAnagrams = [...anagrams.slice(1), anagrams[0]];
      setAnagrams(updatedAnagrams);
      setCurrentAnagram(updatedAnagrams[0]);
      setSelectedLetters([]);
      setUsedLetters([]);
    }
  };

  const [isUndoDisabled, setIsUndoDisabled] = useState(false);

  const handleUndo = () => {
    if (isUndoDisabled || selectedLetters.length === 0) return;

    setIsUndoDisabled(true);

    const updatedUsedLetters = usedLetters.slice(0, -1);
    setUsedLetters(updatedUsedLetters);

    const updatedSelectedLetters = selectedLetters.slice(0, -1);
    setSelectedLetters(updatedSelectedLetters);

    setTimeout(() => {
      setIsUndoDisabled(false);
    }, 500);
  };


  const handleFinishGame = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setStartGame(false);
      history.push('/games?game=3');
    }, 5000);
  };

  // useEffect(() => {
  //   if (gameStarted) {
  //     const handleBeforeUnload = (event) => {
  //       event.preventDefault();
  //       event.returnValue = '';
  //       toast('Je kunt het spel niet opnieuw spelen als je de pagina verlaat!', {
  //         position: 'bottom-center',
  //         duration: 5000,
  //         style: {
  //           background: '#facc15',
  //           color: '#000',
  //         },
  //       });

  //       const saveProgressOnExit = async () => {
  //         try {
  //           await saveGameProgress(userId, gameId, correctCount);
  //         } catch (error) {
  //           console.error('Error saving progress on exit:', error.message);
  //         }
  //       };
  //       saveProgressOnExit();
  //     };
  //     window.addEventListener('beforeunload', handleBeforeUnload);
  //     return () => {
  //       window.removeEventListener('beforeunload', handleBeforeUnload);
  //     };
  //   }
  // }, [gameStarted, correctCount]);

  const handleBeforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = '';
    toast('Je kunt het spel niet opnieuw spelen als je de pagina verlaat!', {
      position: 'bottom-center',
      duration: 5000,
      style: {
        background: '#facc15',
        color: '#000',
      },
    });
  
    const saveProgress = async () => {
      try {
        if (existingGameId) {
          await updateGameData(existingGameId, correctCount);
        } else {
          const response = await storeGameData(userId, gameId, shopId, correctCount);
          setExistingGameId(response.result.id);
        }
      } catch (error) {
        console.error('Error saving progress on exit:', error.message);
      }
    };
    saveProgress();
  };
  
  useEffect(() => {
    if (gameStarted && !gameFinished) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }
  
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [gameStarted, gameFinished, existingGameId, userId, gameId, shopId, correctCount]);
  
  useEffect(() => {
    if (gameFinished) {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }
  }, [gameFinished]);

  useEffect(() => {
    if (gameFinished) {
      setShowAnagramList(true);
    }
  }, [gameFinished]);

  if (!gameStarted && startGame) {
    return (
      <>
        <GameIntro {...gameIntroText} onStart={beginGame} setIsMusicMuted={setIsMusicMuted} />
      </>
    );
  }

  if (loading) {
    return (
      <div className="game-loader">
        <video
          src={gamesTransitionVideo}
          autoPlay
          muted
          onEnded={() => setLoading(false)}
          className="transition-video"
        />
      </div>
    );
  }

  const handleOptionBoxClick = (index) => {
    if (isOptionBoxDisabled || !selectedLetters[index]) return;
  
    setIsOptionBoxDisabled(true);
  
    const updatedSelectedLetters = [...selectedLetters];
    const removedLetter = updatedSelectedLetters.splice(index, 1)[0];
    setSelectedLetters(updatedSelectedLetters);
  
    const updatedUsedLetters = [...usedLetters];
    const removedUsedLetterIndex = usedLetters.findIndex(
      (item) => item.letter === removedLetter && !updatedSelectedLetters.includes(item.letter)
    );
    
    if (removedUsedLetterIndex !== -1) {
      updatedUsedLetters.splice(removedUsedLetterIndex, 1);
      setUsedLetters(updatedUsedLetters);
    }
  
    setTimeout(() => {
      setIsOptionBoxDisabled(false);
    }, 500);
  };

  if (gameFinished) {
    const saveProgress = async () => {
      try {
        if (existingGameId) {
          // Update the existing entry
          await updateGameData(existingGameId, correctCount);
        } else {
          // Create a new entry
          const response = await storeGameData(userId, gameId, shopId, correctCount);
          setExistingGameId(response.result.id);
        }
        if (fetchScore) {
          fetchScore(true);
        }
      } catch (error) {
        console.error('Error saving game progress:', error.message);
      }
    };
    saveProgress();
    return (
      <>
        <GameResult
          score={correctCount}
          isAnagramGame={true}
          onNextGame={handleFinishGame}
        />
        {showAnagramList && (
          <div className="games-anagram-modal">
            <div className="modal-content-anagram">
              <button className="close-button games-button-green" onClick={() => setShowAnagramList(false)}>×</button>
              <h2>ALLE ANAGRAMMEN EN OPLOSSINGEN</h2>
              <div className="anagram-list">
                {originalAnagrams.map((item, index) => (
                  <div key={index} className="anagram-item">
                    <p><strong>ANAGRAM:</strong> {item.scrambled}</p>
                    <p><strong>OPLOSSING:</strong> {item.correct}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </>
    );
  }

  return (
    <div className={`game-screen ${!startGame ? 'game-screen-video' : ''}`}>
      <div className="highlight-current-game">2</div>
      {startGame && (
        <div className="top-right-wooden-sign-container">
          <p>Maak van de gehusselde letters een woord dat te maken heeft met ontspanning en natuur</p>
        </div>
      )}
      {!startGame ? (
        <VideoPlaceholder setIsMusicMuted={setIsMusicMuted} />
      ) : (
        <div className="routekaart-content">
          <div className="routekaart-description">
            <h2>Routekaart</h2>
            <p>
              In dit spel krijgt je gedurende 3 minuten gehusselde letters te zien in het centrale thema. <strong>Klik op de letters</strong>, en zet deze in de juiste volgorde.
            </p>
            <p>Maak zoveel mogelijk bestaande woorden binnen 3 minuten!</p>
            <h3>Voorbeelden</h3>
            <p>
              Woord dat wordt getoond:<br />
              ldaBreen &gt; oplossing: Bladeren<br />
              Woord dat wordt getoond:<br />
              Etouknehi &gt; oplossing: Eikenhout
            </p>
            <h3>Tips:</h3>
            <p>Sla woorden over als je het echt niet weet, deze komen achteraan de wachtrij!</p>
            <p>De woorden hebben te maken met natuur & ontspanning!</p>
          </div>
          <div className="routekaart-game">
            {startGame && showCountdown && (
              <div className="games-countdown-overlay">
                <svg className="games-progress-circle" viewBox="0 0 100 100">
                  <defs>
                    <linearGradient id="green-gradient" x1="0%" y1="0%" x2="100%" y2="100%">
                      <stop offset="0%" stop-color="#67f16a" />
                      <stop offset="50%" stop-color="#00a651" />
                      <stop offset="100%" stop-color="#0b8d37" />
                    </linearGradient>
                  </defs>
                  <circle className="games-progress-background" cx="50" cy="50" r="45" strokeWidth="10" />
                  <circle
                    className="games-progress-bar"
                    cx="50"
                    cy="50"
                    r="45"
                    strokeWidth="10"
                    style={{ strokeDashoffset: `${283 - (283 * countdown) / 10}` }}
                  />
                </svg>
                <div className="countdown-text">{countdown}</div>
              </div>)}
            <div className="letters-grid">
              {scrambledLetters.map((letter, index) => (
                <div
                  className={`letter-box ${gameStatus === 'lost' || usedLetters.some(item => item.index === index) ? 'disabled' : ''}`}
                  key={index}
                  onClick={() => handleLetterClick(letter, index)}
                >
                  {!usedLetters.some(item => item.index === index) ? letter : ''}
                </div>
              ))}
            </div>
            <div className="options-grid">
              {Array.from({ length: currentAnagram.correct.length }).map((_, index) => (
                <div
                  className="option-wrapper"
                  key={index}
                  onClick={() => handleOptionBoxClick(index)}
                >
                  <div className="option-box">
                    {selectedLetters[index] || ''}
                  </div>
                </div>
              ))}
            </div>

            <div className={`progress-bar ${wrongGuess ? 'wrong-guess' : ''}`}>
              <div className="progress" style={{ width: `${progress}%` }}></div>
            </div>

            <div className="games-correct-count">
              <p>
                <span className="checkmark-icon">✔️</span> Juiste antwoorden: {correctCount}
              </p>
            </div>

            <button className="games-undo-button games-button-green" onClick={handleUndo}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="white"
                width="24px"
                height="24px"
              >
                <path d="M12 4V1L7 6l5 5V7c3.31 0 6 2.69 6 6 0 1.3-.42 2.5-1.12 3.46l1.46 1.46C19.46 16.57 20 14.86 20 13c0-4.42-3.58-8-8-8zm-2.88 7.12L7.76 8.76 4.88 5.88 3.46 7.3 7.76 11.6l1.46-1.48z" />
              </svg>
            </button>

            <button className="games-skip-button games-button-green" onClick={handleSkip}>
              Overslaan
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="24px" height="24px">
                <path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.59 5.59L12 20l8-8-8-8z" />
              </svg>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AnagramGame;