import React, { useState, useEffect, useRef, useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import BobMarleyQuiz from '../third-game/BobMarleyQuiz';
import FlagAndSportQuiz from '../first-game/FlagAndSportQuiz';
import AnagramGame from '../second-game/AnagramGame';
import BeeGame from '../fourth-game/BeeGame';
import KeywordQuiz from '../fifth-game/KeywordQuiz';
import WordSearch from '../sixth-game/WordSearch';
import PrizeModal from '../prize-modal/PrizeModal';
import queryString from 'query-string';
import './Games.scss';
import { getUserProgress } from '../services/gameProgressService';
import game1Sound from '../../../../sounds/GAME-1.mp3';
import game2Sound from '../../../../sounds/GAME-2.wav';
import game3Sound from '../../../../sounds/GAME-3.wav';
import game4Sound from '../../../../sounds/GAME-4.mp3';
import game5Sound from '../../../../sounds/GAME-5.mp3';
import game6Sound from '../../../../sounds/GAME-6.mp3';
import introSound from '../../../../sounds/GAME-1.mp3';
import { TcmsContext } from '../../../../contexts/TcmsContext';
import backpack from '../assets/backpack.png';
import jwtDecode from 'jwt-decode';
import { toast } from 'react-hot-toast';
import { getGameScoresByUser, deleteGameData } from '../services/gameProgressService';
import { handleSpeech } from '../services/sharedUtil';
import axios from 'axios';
import GameIntroVideo from '../game-intro/GameIntroVideo';

const quizSounds = [
  game1Sound,
  game2Sound,
  game3Sound,
  game4Sound,
  game5Sound,
  game6Sound,
  introSound
];

const games = [
  { id: 0, name: "Welcome page", prize: "", backgroundImage: "/media/wallpapers/games_wallpaper.jpg", description: "" },
  { id: 1, name: "Game 1", prize: "Gerecyclede plastic fles", backgroundImage: "/media/wallpapers/game1_wallpaper.jpg", image: "/media/wallpapers/game1_prize.jpeg", description: "In de webshop is de BE O Bottle te kiezen als duurzaam cadeau. Deze duurzame waterfles, gemaakt van bioplastic uit suikerriet, is recyclebaar, BPA-vrij en klimaatpositief. Een praktische en milieuvriendelijke keuze die ook nog eens bijdraagt ​​aan herbebossing." },
  { id: 2, name: "Game 2", prize: "Terrarium Steffi", backgroundImage: "/media/wallpapers/game2_wallpaper.jpg", image: "/media/wallpapers/game2_prize.png", description: "In de webshop kan gekozen worden voor het Terrarium Steffi. Dit handgemaakte terrarium bevat verse planten en een zelfvoorzienend ecosysteem, waardoor het geen onderhoud vereist en direct een groene sfeer in huis brengt. Kies voor het Terrarium Steffi en haal een uniek stukje natuur in huis." },
  { id: 3, name: "Game 3", prize: "House of Marley headphones", backgroundImage: "/media/wallpapers/game3_wallpaper.jpg", image: "/media/wallpapers/game3_prize.jpeg", description: "Geniet van de muziek met deze stijlvolle en duurzame House of Marley headphones, gemaakt van gerecycleerde materialen." },
  { id: 4, name: "Game 4", prize: "Bijenhotel Capi Biodiversity", backgroundImage: "/media/wallpapers/game4_wallpaper.jpg", image: "/media/wallpapers/game4_prize.jpeg", description: "Help de natuur met dit bijenhotel, perfect voor jouw tuin." },
  { id: 5, name: "Game 5", prize: "Xtorm 20W solarcharger", backgroundImage: "/media/wallpapers/game5_wallpaper.jpg", image: "/media/wallpapers/game5_prize.png", description: "In de webshop is onder andere te kiezen voor de Xtorm Fuel Series Solar Powerbank 10.000mAh 20W als geschenk.Deze robuuste powerbank beschikt over een ingebouwd zonnepaneel, waardoor je onderweg je apparaten kunt opladen met zonne-energie.Een ideale keuze voor avonturiers die waarde hechten aan duurzaamheid en betrouwbaarheid." },
  { id: 6, name: "Game 6", prize: "Multipillow van gerecycled PET", backgroundImage: "/media/wallpapers/game6_wallpaper.jpg", image: "/media/wallpapers/game6_prize.jpeg", description: "In de webshop is de Gusta multifunctionele kussen als geschenk te kiezen. Dit veelzijdige kussen, gemaakt van gerecyclede PET-flessen, biedt zachtheid en comfort in alle opzichten. Voor ieder gekozen exemplaar schenkt het Leger des Heils een kop soep vanuit de Soepfiets. Gebruik het als hand- of voetverwarmer, stijlvol stoelkussen, of decoratief element op je bank. Een verantwoorde en multifunctionele keuze voor elk interieur." }
];

const gameScreens = {
  1: FlagAndSportQuiz,
  2: AnagramGame,
  3: BobMarleyQuiz,
  4: BeeGame,
  5: KeywordQuiz,
  6: WordSearch,
};

function getIconName(gameId) {
  switch (gameId) {
    case 3:
      return 'game-3.svg';
    case 1:
      return 'game-1.svg';
    case 4:
      return 'game-4.svg';
    case 2:
      return 'game-2.svg';
    case 5:
      return 'game-5.svg';
    case 6:
      return 'game-6.svg';
    default:
      return 'cabin.svg';
  }
}

function stopSpeech() {
  window.speechSynthesis.cancel();
}

const LOADING_DELAY = 3000;

function Games() {

  const [toastShown, setToastShown] = useState(false);
  const { setJwtForGames } = useContext(TcmsContext);
  const [userId, setUserId] = useState('');
  const location = useLocation();
  const history = useHistory();
  const quizAudioRef = useRef(null);
  const [isMuted, setIsMuted] = useState(false);
  const [volume, setVolume] = useState(1);
  const [showWelcomeMsg, setshowWelcomeMsg] = useState(true);
  const [isPrizeModalOpen, setIsPrizeModalOpen] = useState(false);
  const parsedQuery = queryString.parse(location.search);
  const gameFromURL = parsedQuery.game ? parseInt(parsedQuery.game) : 0;
  const [startGame, setStartGame] = useState(parsedQuery.start === 'true');
  const [activeGame, setActiveGame] = useState(gameFromURL);
  const currentGame = games.find(game => game.id === activeGame);
  const [loading, setLoading] = useState(true);
  const [totalScore, setTotalScore] = useState(0);
  const [backpackSize, setBackpackSize] = useState(1.6);
  const [isGrowing, setIsGrowing] = useState(false);
  const [previousBackpackSize, setPreviousBackpackSize] = useState(1);
  const [isMusicMuted, setIsMusicMuted] = useState(true);
  const [isIntro, setIsIntro] = useState(true);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [totalPoints, setTotalPoints] = useState(0);
  const [shopId, setShopId] = useState('');

  const [showGameIntroVideo, setShowGameIntroVideo] = useState(true);
  const videoIntroURL = "https://player.vimeo.com/video/1036361979";
  const [showOverlay, setShowOverlay] = useState(false);
  const [overlayMessage, setOverlayMessage] = useState('');
  const [firstMissingGame, setFirstMissingGame] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');


  const [validationComplete, setValidationComplete] = useState(false);

  // Used to delete entries to re-do the game, find which IDs to delete by checking the get-scores response
  // const deleteAllEntries = async () => {
  //   try {
  //     for (let i = 159; i <= 180; i++) {
  //       try {
  //         await deleteGameData(i); // Pass the index as the id
  //         console.log(`Deleted entry with id: ${i}`);
  //       } catch (error) {
  //         console.warn(`Failed to delete entry with id: ${i} - ${error.message}`);
  //         // Continue to the next iteration even if an error occurs
  //       }
  //     }
  //     console.log("All entries processed!");
  //   } catch (error) {
  //     console.error("Unexpected error during deletion:", error.message);
  //   }
  // };62, 63, 64, 66, 201, 210, 211, 273, 275



  // const deleteEntries = async () => {
  //       try {
  //         await deleteGameData(275); // Pass the index as the id
  //       } catch (error) {
  //         // Continue to the next iteration even if an error occurs
   
  //   }
  // }
  // useEffect(() => {
  //   deleteEntries();
  // }, []);


  useEffect(() => {
    const originalFontFamily = document.body.style.fontFamily;
    document.body.style.fontFamily = '"Open Sans", sans-serif';
    return () => {
      // Restore original font family on unmount
      document.body.style.fontFamily = originalFontFamily;
    };
  }, []);

  useEffect(() => {
    const storedUserId = localStorage.getItem('userId');
    if (storedUserId) {
      setUserId(storedUserId);
    } else {
      console.error("User ID not found in localStorage.");
    }
  }, []);

  const maxBackpackSize = 3;

  const handleVideoEnd = () => {
    setShowGameIntroVideo(false);
  };

  const toggleSpeech = () => {
    const text = `Welkom bij de portal voor het kiezen van je eindejaarsgeschenk. Er is gekozen voor een aanbod van geschenken en belevenissen waar genoeg over valt te vertellen. Dit doen we graag door een virtuele wandeling af te leggen langs 6 rustplekken waar je een spel speelt. Wat kun je winnen? Iedereen wint 10% korting bij Cabiner als je alle spellen uitspeelt. De 10 deelnemers met de meeste punten winnen een nacht voor 2 personen in de Nederlandse natuur. De winnaars worden in mei 2025 bekend gemaakt en worden hier persoonlijk van op de hoogte gesteld. Je kunt het spel spelen tot 1 april 2025. Ondertussen kan je uiteraard ook je geschenk uitkiezen in de webshop. Veel shop- en spelplezier! Cabiner is een netwerk van wandelroutes en off-grid cabins op de mooiste plekken in de Nederlandse natuur.`;
    handleSpeech(text, isSpeaking, setIsSpeaking);
  };

  const calculateBackpackSize = (points) => {
    const baseSize = 1.8;
    const sizeIncrement = 0.5;
    const maxSize = 4;
    return Math.min(baseSize + Math.floor(points / 5) * sizeIncrement, maxSize);
  };

  const fetchScore = async (shouldGrow = false) => {
    const maxRetries = 5;
    let attempts = 0;
  
    try {
      if (!userId) return;
      setIsLoading(true);
      let progressResponse = null;
      while (attempts < maxRetries) {
        try {
          progressResponse = await getGameScoresByUser(userId);
          if (progressResponse) break; 
        } catch (error) {
          console.error(`Attempt ${attempts + 1} failed`, error);
          attempts += 1;
          if (attempts >= maxRetries) {
            throw new Error('Max retries reached');
          }
        }
      }
  
      if (!progressResponse) return;
  
      const totalScore = progressResponse.result.reduce((sum, game) => sum + game.score, 0);
      setTotalPoints(totalScore);
      setTotalScore(totalScore);
      const newBackpackSize = calculateBackpackSize(totalScore);
      if (shouldGrow) {
        setIsGrowing(true);
      }
      const totalAnimationDuration = 8100;
      setTimeout(() => {
        setIsGrowing(false);
      }, totalAnimationDuration);
      setBackpackSize(newBackpackSize);
    } catch (error) {
      console.error("Error fetching score:", error);
      setErrorMessage("Server error, please try again later / Serverfout, probeer het later opnieuw");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchScore();
    }
  }, [userId]);

  useEffect(() => {
    const storedJwtToken = sessionStorage.getItem('jwt_token');
    if (storedJwtToken) {
      axios.defaults.headers.common['MtgJwt'] = storedJwtToken;
      setJwtForGames(storedJwtToken);
      const tokenData = jwtDecode(storedJwtToken);
      const userId = tokenData?.usr?.uid;
      setShopId(tokenData?.usr?.wid);
      if (userId) {
        setUserId(userId); // Set user ID if available
      } else {
        // Always redirect to '/games'
        const fullRedirectUrl = `/login?redirectUrl=${encodeURIComponent('/games')}`;
        window.location.replace(fullRedirectUrl);
      }
    } else {
      // Always redirect to '/games'
      const fullRedirectUrl = `/login?redirectUrl=${encodeURIComponent('/games')}`;
      window.location.replace(fullRedirectUrl);
    }
  }, [setJwtForGames, location]);

  // Fetch user progress when the component mounts and handle redirection
  // TODO: COMMENT THIS SO ITS NOT ANNOYING WHEN DEVELOPING
  useEffect(() => {
    const fetchProgress = async () => {
      try {
        const progressResponse = await getGameScoresByUser(userId);
        const currentGameNumber = parseInt(queryString.parse(location.search).game, 10) || 0;
        const userProgress = progressResponse.result || [];
        const existingGameIds = userProgress.map((game) => game.game_id);
        const totalGames = 6;
        const missingGame = Array.from({ length: totalGames }, (_, i) => i + 1).find(
          (gameId) => !existingGameIds.includes(gameId)
        );

        setFirstMissingGame(missingGame); // Store the missing game globally

        if (currentGameNumber === 0) {
          return;
        }

        if (!missingGame) {
          // All games completed
          if (!toastShown) {
            setOverlayMessage("Je hebt alle spellen voltooid.");
            setShowOverlay(true);
            setStartGame(false);
            setToastShown(true);
          }
          return;
        }

        if (currentGameNumber > missingGame) {
          // User tried to access a game that is not yet unlocked
          setOverlayMessage(
            `Dit spel is nog niet beschikbaar. Ga verder naar spel ${missingGame}.`
          );
          setShowOverlay(true);
        } else if (currentGameNumber < missingGame) {
          // User tries to access a previously completed game
          setOverlayMessage(
            `Je hebt dit spel al voltooid. Ga verder naar spel ${missingGame}!`
          );
          setShowOverlay(true);
        }
      } catch (error) {
        console.error("Failed to load user progress", error);
      }
    };


    if (userId && !toastShown) {
      fetchProgress();
    }
  }, [userId, history, location.search, toastShown]);

  const redirectGame = (gameId) => {
    if (currentGame.id === gameId) {
      return;
    }
    if (startGame) {
      const userConfirmed = window.confirm("Je verliest je voortgang in dit spel. Weet je het zeker?");
      if (!userConfirmed) return;
    }
    setStartGame(false);
    window.location.href = `/games?game=${gameId}`;
  }

  const handleOverlayButtonClick = () => {
    if (!firstMissingGame) {
      // If all games are complete, redirect to final result
      history.replace(`/final-result`);
    } else {
      // Otherwise, go to the first missing game
      history.replace(`?game=${firstMissingGame}`);
    }
    setShowOverlay(false);
  };

  useEffect(() => {
    setActiveGame(gameFromURL);
    if (gameFromURL !== 0) {
      setshowWelcomeMsg(false);
    }
  }, [gameFromURL]);

  useEffect(() => {
    setLoading(true);
    const query = queryString.stringify({ game: activeGame });
    history.replace({ search: `?${query}` });

    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, LOADING_DELAY);

    return () => clearTimeout(timeoutId);

  }, [activeGame, history]);

  const handleStartGame = () => {
    const startingGame = firstMissingGame || 1;
    setActiveGame(startingGame);
    setshowWelcomeMsg(false);
    stopSpeech();
  };

  const handleVolumeChange = (newVolume) => setVolume(newVolume);
  const openPrizeModal = () => setIsPrizeModalOpen(true);
  const closePrizeModal = () => setIsPrizeModalOpen(false);


  const renderGameScreen = () => {
    const GameScreenComponent = gameScreens[activeGame];
    if (GameScreenComponent) {
      return (
        <GameScreenComponent
          startGame={startGame}
          setStartGame={setStartGame}
          setIsMusicMuted={setIsMusicMuted}
          setIsIntro={setIsIntro}
          fetchScore={fetchScore}
        />
      );
    }
    return null;
  };

  const handleToggleMute = () => {
    setIsMuted(!isMuted);
    if (isMuted) {
      quizAudioRef.current.play();
    } else {
      quizAudioRef.current.pause();
    }
  };

  const backgroundStyle = {
    backgroundImage: activeGame === 0
      ? `url(${games[0].backgroundImage})`
      : `url(${games[activeGame]?.backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  if (errorMessage) {
    return (
      <div className="welcome-screen">
        <div className="error-screen">
          <div className="error-message">
            {errorMessage}
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="welcome-screen" style={backgroundStyle}>
      {isLoading && (
        <div className="welcome-screen-loader">
          <div className="loader-overlay">
            <div className="loader-spinner"></div>
          </div>
        </div>
      )}
        <header className="header">
          <div className="settings">
          </div>
        </header>
        {showOverlay && (
          <div className="global-overlay">
            <div className="global-overlay-content">
              <p>{overlayMessage}</p>
              <button
                className="button primary games-button-green"
                onClick={handleOverlayButtonClick}
              >
                {overlayMessage.includes("alle spellen voltooid")
                  ? "Bekijk eindresultaat"
                  : "Volgende uitdaging"}
              </button>
            </div>
          </div>
        )}
        {showWelcomeMsg ? (
          <div className="content content-bg">
            <div className="text-box">
              <h1>Welkom bij het beginscherm van het spel</h1>
              <p>
                Er is gekozen voor een aanbod van geschenken en belevenissen waar genoeg over valt te vertellen. Dit doen we graag door een virtuele wandeling af te leggen langs 6 rustplekken waar je een spel speelt.
                <br></br> <br></br>
                <strong>Wat kun je winnen?</strong>  <br></br>
                Iedereen wint 10% korting bij Cabiner* als je alle spellen uitspeelt. De 10 deelnemers met de meeste punten winnen een nacht voor 2 personen in de Nederlandse natuur. De winnaars worden in mei 2025 bekend gemaakt en worden hier persoonlijk van op de hoogte gesteld.
                <br></br><br></br>
                Je kunt het spel spelen tot 1 april 2025. Ondertussen kan je uiteraard ook je geschenk uitkiezen in de webshop. Veel shop- en spelplezier!
                <br></br><br></br>
                <em>*Cabiner is een netwerk van wandelroutes en off-grid cabins op de mooiste plekken in de Nederlandse natuur.</em>
              </p>
              <div className='welcome-buttons'>
                <button
                  aria-label={`Start het spel ${firstMissingGame || 1}`}
                  className="button primary games-button-green"
                  onClick={handleStartGame}
                >
                  {`Start spel ${firstMissingGame || 1}`}
                </button>
                <button
                  className="button secondary"
                  onClick={() => (window.location.href = '/geschenk-2024/webshop')}
                >
                  Naar de webshop
                </button>
              </div>
            </div>
            <div className="image-box">
              <img
                src="/media/wallpapers/cover.jpg"
                alt="Forest"
              />
              <div className="video-overlay">
              <iframe
                src={`${videoIntroURL}?autoplay=1&loop=0&autopause=1`}
                width="100%"
                height="100%"
                frameBorder="0"
                allow="fullscreen; encrypted-media"
                allowFullScreen
                title="Embedded Video"
              ></iframe>
              </div>
              <div className="overlay">
                <p>Start jouw wandeling in de Nederlandse natuur</p>

                <div className="listen">
                  <button className="tts-button games-button-green" onClick={() => toggleSpeech()}>
                    <img src="/media/icons/listen.svg" alt="Listen Icon" className="tts-icon" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="content">
            {renderGameScreen()}
          </div>
        )}
        <footer className="footer">
          <div className="footer-button-container">
            <a href='/geschenk-2024/webshop' className="footer-button">
              <img
                src="/media/icons/gift.png"
                alt="gift icon"
                className="footer-icon" />
              Naar de webshop
              <span className="arrow-right">{' '}</span>
            </a>
          </div>
          {activeGame !== 0 && (
            <div className="steps">
              {games
                .filter(game => game.id !== 0)
                .map((game, index, arr) => (
                  <React.Fragment key={game.id}>
                    <div
                      onClick={() => redirectGame(game.id)}
                      className={`step ${game.id === activeGame ? 'active' : ''}`}
                    >
                      <img
                        src={`/media/icons/stepper/${getIconName(game.id)}`}
                        alt={`${game.name} icon`}
                        className="step-icon"
                      />
                    </div>
                    {index < arr.length - 1 && (
                      <img
                        src="/media/icons/stepper/steps.svg"
                        alt="Step connector"
                        className="step-connector"
                      />
                    )}
                  </React.Fragment>
                ))}
            </div>
          )}
          {activeGame !== 0 && (
            <div className="footer-icons">
              <div className="backpack-container">
                <img
                  src={backpack}
                  alt="backpack icon"
                  className={`footer-icon prize backpack ${isGrowing ? 'grow-and-shake' : ''}`}
                  style={{
                    width: `${backpackSize * 35}px`,
                    height: `${backpackSize * 35}px`,
                  }}
                  onClick={openPrizeModal}
                />
                {!isGrowing && (
                  <div className="point-indicator">
                    {totalPoints || 0}
                  </div>
                )}
              </div>
            </div>
          )}
        </footer>
      </div>
      <PrizeModal
        show={isPrizeModalOpen}
        onClose={closePrizeModal}
        description={currentGame?.description}
        totalPoints={totalPoints}
      >
        <p> <b>{currentGame?.prize}</b> </p>
        <img
          src={currentGame?.image}
          alt={currentGame?.prize}
          style={{
            width: '100%',
            maxWidth: '400px',
            maxHeight: '50vh',
            objectFit: 'contain',
            borderRadius: '10px',
          }}
        />
      </PrizeModal>
    </>
  );
}

export default Games;
