import React, { useState, useEffect, useContext } from "react";
import VideoPlaceholder from "../video-placeholder/VideoPlaceholder";
import GameIntro from '../game-intro/GameIntro';
import { storeGameData, getGameScoresByUser, updateGameData } from "../services/gameProgressService";
import "./BobMarleyQuiz.scss";
import { useHistory } from 'react-router-dom';
import { TcmsContext } from '../../../../contexts/TcmsContext';
import jwtDecode from 'jwt-decode';
import { toast } from 'react-hot-toast';
import GameResult from '../game-result/GameResult';
import gamesTransitionVideo from '../assets/games_transition.mp4';
import top2000picture from '../assets/top2000.jpg';
import pingSound from '../../../../sounds/ping.mp3';
import buzzSound from '../../../../sounds/softbuzz.mp3';
import { handleSpeech } from '../services/sharedUtil';
import axios from "axios";

const BobMarleyQuiz = ({ startGame, setStartGame, setIsMusicMuted, setIsIntro, fetchScore }) => {
  const [isSpeaking, setIsSpeaking] = useState(false);
  const { setJwtForGames } = useContext(TcmsContext);
  const [timeLeft, setTimeLeft] = useState(300);
  const [isQuizStarted, setIsQuizStarted] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [quizTime, setQuizTime] = useState(0);
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [isQuizFinished, setIsQuizFinished] = useState(false);
  const [disableOptions, setDisableOptions] = useState(false);
  const [gameStarted, setGameStarted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [score, setScore] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [shopId, setShopId] = useState('');
  const [existingGameId, setExistingGameId] = useState(null);

  const history = useHistory();

  const goToNextGame = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setStartGame(false);
      history.push('/games?game=4');
    }, 5000);
  };

  const beginGame = () => {
    setGameStarted(true);
    stopSpeech();
    setIsIntro(false);
  };

  const toggleSpeech = () => {
    const text = `
        De Top 2000 is uitgegroeid tot een ware traditie in Nederland, een jaarlijks muziekfestijn dat miljoenen mensen samenbrengt. Elk jaar tussen Kerst en Oud en Nieuw stemmen muziekliefhebbers massaal op hun favoriete nummers, wat resulteert in een unieke lijst die het muzikale landschap van jong en oud weerspiegelt. Sinds de eerste uitzending in 1999 door NPO Radio 2, is de Top 2000 een niet te missen evenement geworden dat de harten van vele Nederlanders heeft veroverd waar jaarlijks circa 10 miljoen mensen naar kijken en luisteren.
        
        Het populairste nummer ooit? Dat is zonder twijfel "Bohemian Rhapsody" van Queen, dat maar liefst 19 keer op de eerste plek heeft gestaan. Deze tijdloze klassieker heeft generaties geraakt met zijn unieke compositie en krachtige melodieën. Toch laat de Top 2000 zien dat muziek voortdurend in beweging is. In 2020 werd Queen verrassend van de troon gestoten door "Roller Coaster" van Danny Vera, een nummer dat razendsnel aan populariteit won en een nieuwe favoriet werd onder luisteraars. Dit benadrukt hoe de lijst zich blijft vernieuwen en hoe nieuwe muziek een plek verovert naast de klassiekers.
        
        Wat de Top 2000 zo bijzonder maakt, is de mix van generaties en genres. Van rockiconen als The Rolling Stones en The Beatles tot Nederlandstalige parels zoals "Avond" van Boudewijn de Groot, en de moderne klanken van artiesten als Dua Lipa. Het is een feest van herkenning en ontdekking voor iedereen. Opvallend is ook de opkomst van De Bankzitters, een groep populaire Nederlandse YouTubers. Dankzij hun trouwe en jonge fanbase hebben zij met nummers als "Cupido" en "Je Blik Richting Mij" een plek in de lijst weten te veroveren. Dit laat de invloed van sociale media en de stem van een nieuwe generatie zien, en hoe muziekplatforms evolueren in het digitale tijdperk.
        
        Queen blijft echter de onbetwiste hofleverancier van de lijst met maar liefst 36 nummers, gevolgd door The Beatles met 28 en ABBA met 25 noteringen. De Nederlandse trots BLØF is de band met de meeste eigen nummers in de lijst, namelijk 17 stuks. En nummers als "Hotel California" van The Eagles, die al sinds de allereerste editie in 1999 meedoen, blijven generaties boeien en bewijzen de tijdloze kracht van muziek.
        
        Het stemmen voor de Top 2000 is ook een belevenis op zich. Tijdens het stemweekend kunnen luisteraars hun stem persoonlijk inleveren bij speciale locaties door het hele land, wat bijdraagt aan de feestelijke en verbindende sfeer van het evenement. Er moeten minstens vijf muziektitels worden aangedragen; het maximum werd door de jaren heen in stappen verhoogd naar 35. Het is niet alleen een lijst, maar een nationale viering van muziek en samenkomst.
        
        Met duizenden nummers, verrassende nieuwkomers en tijdloze klassiekers is de Top 2000 meer dan een afspeellijst; het is een jaarlijks muzikaal avontuur dat mensen van alle leeftijden samenbrengt. Of je nu meezingt met Queen, danst op de beats van De Bankzitters, of geniet van een liedje dat je meeneemt naar vervlogen tijden, de Top 2000 verbindt ons allemaal in de liefde voor muziek.
    `;
    handleSpeech(text, isSpeaking, setIsSpeaking);
  };

  function stopSpeech() {
    window.speechSynthesis.cancel();
  }

  const [quizQuestions, setQuizQuestions] = useState([
    {
      question: "In welk jaar begon de uitzending van de Top 2000 op NPO Radio 2?",
      options: ["1998", "1997", "1999"],
      answer: "1999",
    },
    {
      question: "Welk nummer stond het vaakst op de eerste plaats in de Top 2000?",
      options: [
        '"Hotel California" - The Eagles',
        '"Bohemian Rhapsody" - Queen',
        '"Roller Coaster" - Danny Vera',
      ],
      answer: '"Bohemian Rhapsody" - Queen',
    },
    {
      question: 'Wie stootte Queen in 2020 van de troon met het nummer "Roller Coaster"?',
      options: ["BLØF", "Coldplay", "Danny Vera"],
      answer: "Danny Vera",
    },
    {
      question: "Hoeveel nummers heeft Queen in de Top 2000?",
      options: ["28", "36", "42"],
      answer: "36",
    },
    {
      question: "Welke Nederlandse band heeft de meeste nummers in de Top 2000?",
      options: ["BLØF", "Doe Maar", "Son Mieux"],
      answer: "BLØF",
    },
    {
      question: "Welke nummers staan sinds de eerste editie in 1999 onafgebroken in de Top 2000?",
      options: [
        '"Imagine" - John Lennon',
        '"Stairway to Heaven" - Led Zeppelin',
        '"Hotel California" - The Eagles',
      ],
      answer: '"Hotel California" - The Eagles',
    },
    // {
    //   question: "Welke artiest vertegenwoordigt de moderne invloeden in de Top 2000?",
    //   options: ["DuaLipa", "Billie Eilish", "Coldplay"],
    //   answer: "DuaLipa",
    // },
    {
      question: "Met welke nummers wisten De Bankzitters een plek in de lijst te veroveren?",
      options: [
        '"Je Blik Richting Mij" en "Cupido"',
        '"Avond" en "Roller Coaster"',
        '"Yellow" en "Paradise"',
      ],
      answer: '"Je Blik Richting Mij" en "Cupido"',
    },
    // {
    //   question: "Wat maakt het stemmen voor de Top 2000 zo bijzonder?",
    //   options: [
    //     "Het stemproces is geheim.",
    //     "Luisteraars kunnen hun stem persoonlijk inleveren tijdens het stemweekend.",
    //     "Stemmen gebeurt alleen via een app.",
    //   ],
    //   answer:
    //     "Luisteraars kunnen hun stem persoonlijk inleveren tijdens het stemweekend.",
    // },
    // {
    //   question: "Hoeveel nummers heeft The Beatles in de Top 2000 staan?",
    //   options: ["25", "36", "28"],
    //   answer: "28",
    // },
    {
      question: "Op hoeveel nummers kan je maximaal stemmen tijdens de Top 2000?",
      options: [
        '5 nummers',
        '35 nummers',
        'Geen limiet'
      ],
      answer: '35 nummers',
    },
    {
      question: "Hoeveel mensen kijk en luisteren er jaarlijks ongeveer naar de Top2000?",
      options: [
        '6,3 miljoen',
        '8,8 miljoen',
        '10 miljoen',
      ],
      answer: '10 miljoen',
    },
    {
      question: "Waarom wordt de Top 2000 beschouwd als een nationale viering?",
      options: [
        'Het wordt uitgezonden op alle radiozenders.',
        'Het brengt generaties samen en weerspiegelt de liefde voor muziek.',
        'Alleen Nederlandse artiesten staan erin.',
      ],
      answer: 'Het brengt generaties samen en weerspiegelt de liefde voor muziek.',
    },
  ]);

  const gameIntroText = {
    title: 'Naam spel: Lees en weet',
    description: `In de webshop is de House of Marley-koptelefoon te kiezen. Deze stijlvolle hoofdtelefoon biedt niet alleen een superieure geluidskwaliteit, maar is ook vervaardigd met duurzame materialen zoals FSC-gecertificeerd hout en gerecycled aluminium. 
      Door te kiezen voor deze koptelefoon geniet je van je favoriete muziek op een milieuvriendelijke manier. 
      Een bewuste keuze voor zowel audiofielen als het milieu.`,
    showMedals: false,
    subTitle: 'Doel van dit spel: Beantwoord 10 vragen goed over de tekst die je hebt gelezen',
    gift: 'Centraal geschenk bij dit spel',
  };

  const skipReading = () => {
    setIsQuizStarted(true);
    setIsTimerRunning(false);
    stopSpeech();
  };

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  useEffect(() => {
    setQuizQuestions(shuffleArray([...quizQuestions]));
  }, []);

  const [userId, setUserId] = useState('');

  const gameId = 3;

  useEffect(() => {
    const storedJwtToken = sessionStorage.getItem('jwt_token');
    if (storedJwtToken) {
      axios.defaults.headers.common['MtgJwt'] = storedJwtToken;

      const tokenData = jwtDecode(storedJwtToken);
      setUserId(tokenData?.usr?.uid);
      setShopId(tokenData?.usr?.wid);
      setJwtForGames(storedJwtToken);
    }
  }, [setJwtForGames]);

  // Fetch existing game progress to check for existing entries
  useEffect(() => {
    const fetchGameProgress = async () => {
      try {
        const progress = await getGameScoresByUser(userId);
        const gameEntry = progress.result.find(
          (entry) => entry.game_id === gameId && entry.webshoporder_id === shopId
        );
        if (gameEntry) {
          setExistingGameId(gameEntry.id);
        }
      } catch (error) {
        console.error('Error fetching game progress:', error.message);
      }
    };
    if (userId) {
      fetchGameProgress();
    }
  }, [userId, shopId]);

  useEffect(() => {
    if (gameStarted && !isQuizStarted) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime <= 1) {
            setIsQuizStarted(true);
            setIsTimerRunning(true);
            clearInterval(timer);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [gameStarted, isQuizStarted]);

  useEffect(() => {
    let quizTimer;
    if (isTimerRunning) {
      quizTimer = setInterval(() => {
        setQuizTime((prevTime) => prevTime + 1);
      }, 1000);
    }
    return () => clearInterval(quizTimer);
  }, [isTimerRunning]);

  useEffect(() => {
    if (isQuizFinished) {
      const saveProgress = async () => {
        try {
          if (existingGameId) {
            // Update the existing entry
            await updateGameData(existingGameId, score);
          } else {
            // Create a new entry
            const response = await storeGameData(userId, gameId, shopId, score);
            setExistingGameId(response.result.id);
          }
          if (fetchScore) {
            fetchScore(true);
          }
        } catch (error) {
          console.error('Error saving game progress:', error.message);
        }
      };
      saveProgress();
    }
    
  }, [isQuizFinished, quizTime]);

  const handleBeforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = '';
    toast('Je kunt het spel niet opnieuw spelen als je de pagina verlaat!', {
      position: 'bottom-center',
      duration: 5000,
      style: {
        background: '#facc15',
        color: '#000',
      },
    });
  
    const saveProgress = async () => {
      try {
        if (existingGameId) {
          await updateGameData(existingGameId, score);
        } else {
          const response = await storeGameData(userId, gameId, shopId, score);
          setExistingGameId(response.result.id);
        }
      } catch (error) {
        console.error('Error saving progress on exit:', error.message);
      }
    };
    saveProgress();
  };
  
  useEffect(() => {
    if (isQuizStarted && !isQuizFinished) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }
  
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isQuizStarted, score, existingGameId, userId, gameId, shopId]);
  
  useEffect(() => {
    if (isQuizFinished) {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }
  }, [isQuizFinished]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  const handleAnswerClick = (selectedOption) => {
    if (disableOptions) return;

    setSelectedOption(selectedOption);
    setDisableOptions(true);

    const currentQuizQuestion = quizQuestions[currentQuestion];

    if (selectedOption !== currentQuizQuestion.answer) {
      new Audio(buzzSound).play();
      setQuizTime((prevTime) => prevTime + 15);

      const correctButton = Array.from(document.querySelectorAll('button[data-option]')).find(
        (button) => button.getAttribute('data-option') === currentQuizQuestion.answer
      );

      if (correctButton) {
        correctButton.classList.add("correct-answer-highlighted");
      }

      setTimeout(() => {
        goToNextQuestion();
        correctButton.classList.remove("correct-answer-highlighted");
      }, 2000);
    } else {
      setScore((prevScore) => prevScore + 1);
      new Audio(pingSound).play();

      setTimeout(() => {
        goToNextQuestion();
      }, 1500);
    }
  };

  const goToNextQuestion = () => {
    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < quizQuestions.length) {
      setCurrentQuestion(nextQuestion);
      setDisableOptions(false);
    } else {
      setIsTimerRunning(false);
      setIsQuizFinished(true);
    }
  };

  if (loading) {
    return (
      <div className="game-loader">
        <video
          src={gamesTransitionVideo}
          autoPlay
          muted
          onEnded={() => setLoading(false)}
          className="transition-video"
        />
      </div>
    );
  }


  if (!gameStarted && startGame) {
    return (
      <>
        <GameIntro {...gameIntroText} onStart={beginGame} setIsMusicMuted={setIsMusicMuted} />
      </>
    );
  }

  if (isQuizFinished) {
    return <GameResult score={score} totalQuestions={quizQuestions.length} isBobMarleyQuiz={true} onNextGame={goToNextGame} />;
  }

  return (
    <>
      {loading && (
        <div className="game-loader">
          <video
            src={gamesTransitionVideo}
            autoPlay
            muted
            onEnded={() => {
              setLoading(false);
              setStartGame(false);
              history.push('/games?game=4');
            }}
            className="transition-video"
          />
        </div>
      )}
      {!loading && (
        <div className={`game-screen ${!startGame ? 'game-screen-video' : ''}`} style={{ padding: '20px' }}>
          <div className='highlight-current-game'> 3 </div>
          {startGame && (
            <div className="top-right-wooden-sign-container">
              <p>Onthoud de tekst en beantwoord de vragen!</p>
            </div>
          )}
          {!startGame ? (
            <VideoPlaceholder setIsMusicMuted={setIsMusicMuted} />
          ) : (
            <div className="game-content">
              {!isQuizStarted ? (
                <div className="game-section">
                  <h2>Duik in het Muzikale Fenomeen: De Top 2000</h2>
                  <div className="reminder">
                    <p>Lees onderstaande tekst goed door, want deze helpt je bij het beantwoorden van de volgende vragen!</p>
                  </div>
                  <div className="game-text">
                    <p>
                      De Top 2000 is uitgegroeid tot een ware traditie in Nederland, een jaarlijks muziekfestijn dat miljoenen mensen samenbrengt. Elk jaar tussen Kerst en Oud en Nieuw stemmen muziekliefhebbers massaal op hun favoriete nummers, wat resulteert in een unieke lijst die het muzikale landschap van jong en oud weerspiegelt. Sinds de eerste uitzending in 1999 door NPO Radio 2, is de Top 2000 een niet te missen evenement geworden dat de harten van vele Nederlanders heeft veroverd waar jaarlijks circa 10 miljoen mensen naar kijken en luisteren.
                    </p>
                    <p>
                      Het populairste nummer ooit? Dat is zonder twijfel "Bohemian Rhapsody" van Queen, dat maar liefst 19 keer op de eerste plek heeft gestaan. Deze tijdloze klassieker heeft generaties geraakt met zijn unieke compositie en krachtige melodieën. Toch laat de Top 2000 zien dat muziek voortdurend in beweging is. In 2020 werd Queen verrassend van de troon gestoten door "Roller Coaster" van Danny Vera, een nummer dat razendsnel aan populariteit won en een nieuwe favoriet werd onder luisteraars. Dit benadrukt hoe de lijst zich blijft vernieuwen en hoe nieuwe muziek een plek verovert naast de klassiekers.
                    </p>
                    <p>
                      Wat de Top 2000 zo bijzonder maakt, is de mix van generaties en genres. Van rockiconen als The Rolling Stones en The Beatles tot Nederlandstalige parels zoals "Avond" van Boudewijn de Groot, en de moderne klanken van artiesten als Dua Lipa. Het is een feest van herkenning en ontdekking voor iedereen. Opvallend is ook de opkomst van De Bankzitters, een groep populaire Nederlandse YouTubers. Dankzij hun trouwe en jonge fanbase hebben zij met nummers als "Cupido" en "Je Blik Richting Mij" een plek in de lijst weten te veroveren. Dit laat de invloed van sociale media en de stem van een nieuwe generatie zien, en hoe muziekplatforms evolueren in het digitale tijdperk.
                    </p>
                    <p>
                      Queen blijft echter de onbetwiste hofleverancier van de lijst met maar liefst 36 nummers, gevolgd door The Beatles met 28 en ABBA met 25 noteringen. De Nederlandse trots BLØF is de band met de meeste eigen nummers in de lijst, namelijk 17 stuks. En nummers als "Hotel California" van The Eagles, die al sinds de allereerste editie in 1999 meedoen, blijven generaties boeien en bewijzen de tijdloze kracht van muziek.
                    </p>
                    <p>
                      Het stemmen voor de Top 2000 is ook een belevenis op zich. Tijdens het stemweekend kunnen luisteraars hun stem persoonlijk inleveren bij speciale locaties door het hele land, wat bijdraagt aan de feestelijke en verbindende sfeer van het evenement. Er moeten minstens vijf muziektitels worden aangedragen; het maximum werd door de jaren heen in stappen verhoogd naar 35. Het is niet alleen een lijst, maar een nationale viering van muziek en samenkomst.
                    </p>
                    <p>
                      Met duizenden nummers, verrassende nieuwkomers en tijdloze klassiekers is de Top 2000 meer dan een afspeellijst; het is een jaarlijks muzikaal avontuur dat mensen van alle leeftijden samenbrengt. Of je nu meezingt met Queen, danst op de beats van De Bankzitters, of geniet van een liedje dat je meeneemt naar vervlogen tijden, de Top 2000 verbindt ons allemaal in de liefde voor muziek.
                    </p>
                  </div>
                  <div className="buttons">
                    <button className="skip-text-button games-button-green" onClick={skipReading}>
                      Ik heb de tekst gelezen
                    </button>
                    <div className="listen">
                      <button className="tts-button games-button-green" onClick={toggleSpeech}>
                        <img src="/media/icons/listen.svg" alt="Listen Icon" className="tts-icon" />
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="quiz-content">
                  <h1>Quiz: test je kennis</h1>
                  <div className="quiz-question">
                    <div className="question-heading-container">
                      <h2>{quizQuestions[currentQuestion].question}</h2>
                    </div>
                    <div className="quiz-options">
                      {quizQuestions[currentQuestion].options.map((option, index) => (
                        <button
                          key={index}
                          data-option={option}
                          data-index={index}
                          className={`games-button-green quiz-option 
                            ${selectedOption === option && option === quizQuestions[currentQuestion].answer ? "correct-answer" : ""}
                            ${selectedOption === option && option !== quizQuestions[currentQuestion].answer ? "wrong-answer" : ""}`}
                          onClick={() => handleAnswerClick(option)}
                          disabled={disableOptions}
                        >
                          {option}
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              )}
              <div className="image-section">
                <img
                  src={top2000picture} 
                  alt="Bob Marley"
                  className="game-image"
                />
                {!isQuizStarted && (
                  <div className="timer">
                    <span className="timer-text">Resterende tijd: {formatTime(timeLeft)}</span>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default BobMarleyQuiz;
