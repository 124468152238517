import React, { useEffect, useState, useContext } from 'react';
import { getUserFinalResults } from '../services/gameProgressService';
import './FinalResult.scss';
import { TcmsContext } from '../../../../contexts/TcmsContext';
import jwtDecode from 'jwt-decode';
import natureSound from '../../../../sounds/nature.mp3';

const FinalResult = () => {
  const [progress, setProgress] = useState(null);
  const { setJwtForGames } = useContext(TcmsContext);
  const [userId, setUserId] = useState(''); // Keep the userId here

  useEffect(() => {
    const storedJwtToken = sessionStorage.getItem('jwt_token');
    if (storedJwtToken) {
      setJwtForGames(storedJwtToken);
      const tokenData = jwtDecode(storedJwtToken);
      setUserId(tokenData?.usr?.uid);
    }
  }, [setJwtForGames]);

  useEffect(() => {
    const playSound = () => {
      const sound = new Audio(natureSound);
      sound.play().catch((error) => console.error('Failed to play sound:', error));
    };

    playSound();
  }, []);

  // useEffect(() => {
  //   const fetchProgress = async () => {
  //     try {
  //       const userProgress = await getUserFinalResults(userId);
  //       setProgress(userProgress);
  //     } catch (error) {
  //       console.error('Failed to fetch user progress', error);
  //     }
  //   };

  //   if (userId) {
  //     fetchProgress();
  //   }
  // }, [userId]);

  return (
    <div className="final-result-background">
      <div className="final-result-container">
        <div className="centered-box">
          <h1 className="final-result-title">Gefeliciteerd, je hebt de virtuele wandeltocht voltooid!</h1>
          <p className="final-result-text">
            Je bent aangekomen bij je eindbestemming: de Cabin.
            Tijdens je reis heb je je rugzak gevuld met <b> mooie en nuttige geschenken passend bij een overnachting in de natuur! </b>
            Hoe goed je het hebt gedaan, ontdek je in april, wanneer we de 10 gelukkige winnaars bekendmaken die een cadeaubon voor een wandeling naar een prachtige Cabin in de natuur winnen.
            <br></br><br></br>
            Wil je zelf al op pad? Gebruik dan de code <strong>GAMExWANDELINGxCABINER</strong> voor 10% korting op een onvergetelijke wandelroute naar een cabin midden in de natuur. Ga hiervoor naar cabiner.com.
            In de webshop vind je naast de 6 duurzame geschenken die je onderweg hebt leren kennen nog vele andere geschenken.
            <br></br><br></br>
            Bedankt voor het spelen en veel plezier in de webshop!
          </p>
          <button className="webshop-btn" onClick={() => (window.location.href = '/geschenk-2024/webshop')}> Naar de webshop</button>
        </div>
      </div>
    </div>
  );
};

export default FinalResult;
