import { useContext, useState, useEffect } from "react";
import { TcmsContext } from '../../../contexts/TcmsContext';
import TcmsImg from "../../../tcms/Tags/TcmsImg";
import Tcms from "../../../tcms/Tcms";
import TcmsWYSIWYG from "../../../tcms/Tags/TcmsWYSIWYG";
import axios from "axios";
import {useLocation } from 'react-router-dom';


export default function Banner(props) {

  const BE = process.env.REACT_APP_MTG_BACKEND

  var welcomeMessage = false;
  if (props.welcome_message) {
    welcomeMessage = true;
  }
  const context = useContext(TcmsContext);
  const [headerClass, setHeaderClass] = useState('banner-normal');
  const [firstName, setFirstName] = useState(false);

  const location = useLocation()
  const parts = location.pathname.split('/');
  const lastSegment = parts.pop() || parts.pop();  // handle potential trailing slash

  const checkElementVisibility = (value) => {
    if (value.includes("inactive")) {
      return false;
    } else {
      return true;
    }
  }

  const getUser = () => {
    axios.get(BE + '/api/webshop/voucher').then(usr => {
      if (usr.data.firstname) {
        if (usr.data.firstname !== "***") {
          setFirstName(usr.data.firstname);
        }
      }
    })
  }

  const checkFaqBannerImage = (image) => {
    if (image !== "faq_banner_image" && lastSegment === "hoe-werkt-het") {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    if (context.customHeader) {
      setHeaderClass('banner-custom');
    }
  }, [context.customHeader]);

  return (
    <div className={`${headerClass}`}>
      <div className="banner-content">
        <div className="banner">
          {checkFaqBannerImage(Tcms('faq_banner_image')) 
          ?
          <TcmsImg src="faq_banner_image" alt="Alt tekst top menu" className="wavy-image"/>
          :
          <TcmsImg src="/media/images/default/banner.png" alt="Alt tekst top menu" className="wavy-image"/>
          }
          <div className="overlay">
            <div className="container__large">
              <div className="wrapper">
                {welcomeMessage && 
                  <div className={"welcome-message "+ Tcms('welcome_message_class')}>
                    <h1 className="beste"><span><Tcms>banner_beste</Tcms></span> <span>{firstName}</span><span><Tcms>banner_beste_comma</Tcms></span></h1>
                    <TcmsWYSIWYG>Banner_intro</TcmsWYSIWYG>
                  </div>
                }
                <div className={"buttons " + Tcms('toggle_banner_buttons')}>
                  {checkElementVisibility(Tcms('give_gift_label')) &&
                    <button className="button">
                      <a href={Tcms('banner_button_1_link')}>
                        <TcmsImg src="/media/icons/icon-geef-je-geschenk.svg"/><Tcms>give_gift_label</Tcms>
                      </a>
                    </button>
                  }
                  {checkElementVisibility(Tcms('give_points_label')) &&
                    <button className="button">
                      <a href={Tcms('banner_button_2_link')}>
                        <TcmsImg src="/media/icons/icon-schenk-punten.svg"/><Tcms>give_points_label</Tcms>
                      </a>
                    </button>
                  }
                  {checkElementVisibility(Tcms('donate_points_label')) &&
                    <button className="button">
                      <a href={Tcms('banner_button_3_link')}>
                        <TcmsImg src="banner_button_img_3"/><Tcms>donate_points_label</Tcms>
                      </a>
                    </button>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {welcomeMessage && 
        <div className={"welcome-message res-welcome-message "+ Tcms('welcome_message_class')}>
          <h1 className="beste"><span><Tcms>banner_beste</Tcms></span> <span>{firstName}</span></h1>
          <TcmsWYSIWYG>Banner_intro</TcmsWYSIWYG>
        </div>
      }
      {context.editMode &&
        <div className="banner_edit tcms-edit-wrapper tcms-overlay">
          <div className="item">
            <div className="label">Toggle banner buttons</div>
            <div className="value"><Tcms>toggle_banner_buttons</Tcms></div>
          </div>
          <div className='item'>
            <div className="label">Banner image</div>
            <div className="value"><TcmsImg src="/media/images/default/banner.png" alt="Alt tekst top menu"/></div>
          </div>
          <div className='item'>
            <div className="label">FAQ Banner image</div>
            <div className="value"><TcmsImg src="faq_banner_image" alt="Alt tekst top menu"/></div>
          </div>
          <div className='item'>
            <div className="label">Welcome message class</div>
            <div className="value"><Tcms>welcome_message_class</Tcms></div>
          </div>
          <h3>Buttons</h3>
          <div className='item'>
            <div className="label">Button 1</div>
            <div className="value">
              <div className="item"><Tcms>give_gift_label</Tcms></div>
              <div className="item icon-edit"><TcmsImg src="banner_button_img_1"/></div>
              <div className="item"><Tcms>banner_button_1_link</Tcms></div>
            </div>
          </div>
          <div className='item'>
            <div className="label">Button 2</div>
            <div className="value">
              <div className="item"><Tcms>give_points_label</Tcms></div>
              <div className="item icon-edit"><TcmsImg src="banner_button_img_2"/></div>
              <div className="item"><Tcms>banner_button_2_link</Tcms></div>
            </div>
          </div>
          <div className='item'>
            <div className="label">Button 3</div>
            <div className="value">
              <div className="item"><Tcms>donate_points_label</Tcms></div>
              <div className="item icon-edit"><TcmsImg src="banner_button_img_3"/></div>
              <div className="item"><Tcms>banner_button_3_link</Tcms></div>
            </div>
          </div>
        </div>
      }
    </div>
  );
}
